import PayrollToFundDetails from '../PayrollToFundDetails';
import ImportantUpdate from '../ImportantUpdate';
import RepaymentDate from '../RepaymentDate';
import CashAdvance from '../CashAdvance';
import { RequestFundingWrapperProps } from './interfaces';
import FooterButtons from '../../../Footer/footer-buttons';
import Funded from '../Funded';
import DealCancelled from '../DealCancelled/DealCancelled';
import { BankAccount } from '../../../api-utils/generated-client';
import ConnectBankAccounts from '../ConnectBankAccounts/connect-banks';
import Confirm from '../Confirm';
import CashAdvanceConfirm from '../CashAdvanceConfirm';
import VerifyPayroll from '../VerifyPayroll';
import CashAdvanceAgreement from '../CashAdvanceAgreement';
import ProgressBar from '../../../widgets/ProgressBar';
import RangeSlider from '../../../widgets/RangeSlider';
import CongratsPage from '../../Onboarding/congrats';
import LedgerHome from '../../Ledger/LedgerHome';
import { persistAllBankInfo } from '../ConnectBankAccounts/utils';
import {
  FundingStepStateType,
  fundingStepState,
  numOfWeeksState,
  dealDraftState,
  sectionState,
  isAllBankInfoValidState,
  uploadedFilesState,
} from '../../../recoil-state/request-funding-states';
import {
  docVersionState,
  signatureDataUrlState,
} from '../../../recoil-state/application-stage-states';
import {
  accountRecordState,
  mainSectionState,
} from '../../../recoil-state/general-states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getClient } from '../../../api-utils/general-utils';
import { isThereFutureFinchPayroll } from '../../../recoil-state/finch-states';
import DealFailed from '../DealFailed/DealFailed';
import { useHistory } from 'react-router-dom';
import ManualBankForm from '../../../widgets/PlaidConnector/ManualBankForm';
import SignAgreements from '../../Application/SignAgreements';

export default (props: any) => {
  let history = useHistory();
  const [fundingStep, setFundingStep] =
    useRecoilState(fundingStepState);
  const [accountRecord, setAccountRecord] = useRecoilState(
    accountRecordState,
  );
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const [section, setSection] = useRecoilState(sectionState);
  const isAllBankInfoValid = useRecoilValue(isAllBankInfoValidState);
  const isThereFuturePayroll = useRecoilValue(
    isThereFutureFinchPayroll,
  );
  const [signatureDataUrl, setSignaturedataUrl] = useRecoilState<any>(
    signatureDataUrlState,
  );
  const [docVersion, setDocVersion] =
    useRecoilState<string>(docVersionState);
  const uploadedPayrollStatements = useRecoilValue(
    uploadedFilesState,
  );
  const isValidSceenName = (newScreen: string): boolean => {
    const validScreens = [
      'congrats',
      'sign-contract',
      'funding-amount',
      'repayment-date',
      'cash-advance',
      'bank-info',
      'verify-payroll',
      'cash-advance-agreement',
      'confirm',
      'funded',
      'dashboard',
    ];
    return validScreens.includes(newScreen);
  };

  const getMainComponent = () => {
    // console.log('fundingStep',fundingStep)
    switch (fundingStep) {
      case 'congrats':
        return (
          <div>
            <CongratsPage />
          </div>
        );

      // case 'dashboard':
      //   return (
      //     <div>
      //       <LedgerHome />
      //     </div>
      //   );

      case 'funding-amount':
        return (
          <div>
            <ProgressBar
              percentComplete={dealRecord.funding_amount ? 15 : 0}
            />

            <div className="request-funding-main-body">
              <PayrollToFundDetails />

              <FooterButtons
                hideBackButton={true}
                nextDisabled={false}
                mca_account={props.mca_account}
                // userAddressState={props.userAddressState}
              />
            </div>
          </div>
        );

      case 'repayment-date':
        return (
          <div>
            <div>
              <ProgressBar percentComplete={30} />
              <div className="request-funding-main-body">
                <RepaymentDate />
                <FooterButtons
                  hideBackButton={false}
                  nextDisabled={false}
                  mca_account={props.mca_account}
                  //mca_account={props.mca_account} userAddressState={props.userAddressState}
                />
              </div>
            </div>
          </div>
        );

      case 'cash-advance':
        return (
          <div>
            <div>
              <ProgressBar percentComplete={30} />
              <div className="request-funding-main-body">
                <CashAdvance
                  //userAddressState={props.userAddressState}
                  mca_account={props.mca_account}
                />
              </div>
            </div>
          </div>
        );

      case 'bank-info':
        return (
          <div>
            <ProgressBar
              percentComplete={isAllBankInfoValid ? 65 : 50}
            />
            <div className="request-funding-main-body">
              <ConnectBankAccounts
                mca_account={props.mca_account}
                //  userAddressState={props.userAddressState}
                // FooterButtons IS INSIDE OF CONNECT BANK ACCOUNTS
              />
            </div>
          </div>
        );

      case 'verify-payroll':
        return (
          <div>
            <ProgressBar
              percentComplete={
                isThereFuturePayroll != 'yes' &&
                uploadedPayrollStatements?.length < 1
                  ? 75
                  : 85
              }
            />
            <div className="request-funding-main-body">
              <VerifyPayroll />
              <FooterButtons
                nextDisabled={
                  false
                  //TODO  props.isMainInfoNextDisabled()
                }
              />
            </div>
          </div>
        );
      case 'important-update':
        return (
          <>
            <ImportantUpdate />
          </>
        );
      case 'cash-advance-agreement':
        return (
          <div>
            <ProgressBar percentComplete={85} />
            <div className="request-funding-main-body">
              <CashAdvanceAgreement
                //userAddressState={props.userAddressState}
                mcaAccount={props.mca_account}
              />
              {/* <FooterButtons
                  nextDisabled={false}
                  userAddressState={props.userAddressState}
                /> */}
            </div>
          </div>
        );

      case 'confirm':
        if (props.mca_account) {
          return (
            <div>
              <ProgressBar percentComplete={99} />
              <div className="request-funding-main-body">
                <CashAdvanceConfirm
                  confirmButtonClick={async () => {
                    const client = await getClient();
                    try {
                      const dealStatus =
                        await client?.dealsControllerCashAdvanceActivateDeal(
                          {
                            signature: signatureDataUrl,
                            documentVersion: docVersion as string,
                          },
                        );

                      if (dealStatus?.status == 200) {
                        history.push('/funded');
                        setFundingStep('funded');
                      } else {
                        // setFundingStep('failed');
                      }
                    } catch {
                      // setFundingStep('failed');
                    }
                  }}
                />
                <FooterButtons
                  // hideNextButton={true}
                  // nextDisabled={false}
                  // nextType="getFunded"
                  // userAddressState={props.userAddressState}
                  mca_account={props.mca_account}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <ProgressBar percentComplete={95} />
              <div className="request-funding-main-body">
                <Confirm
                  confirmButtonClick={async () => {
                    const client = await getClient();
                    try {
                      const dealStatus =
                        await client?.dealsControllerActivateDeal();

                      if (dealStatus?.status == 200) {
                        history.push('/funded');
                        setFundingStep('funded');
                      } else {
                        setFundingStep('failed');
                      }
                    } catch {
                      setFundingStep('failed');
                    }
                  }}
                />
                <FooterButtons
                  hideNextButton={true}
                  nextDisabled={false}
                  nextType="getFunded"
                />
              </div>
            </div>
          );
        }

      case 'manual-bank-form':
        return (
          <>
            <ProgressBar percentComplete={80} />

            <div className="request-funding-main-body">
              <ManualBankForm />
              <FooterButtons
                hideNextButton={true}
                nextDisabled={false}
              />
            </div>
          </>
        );

      case 'funded':
        return <Funded />;

      case 'cancelled':
        return <DealCancelled />;

      case 'failed':
        return <DealFailed />;

      case 'sign-contract':
        return <SignAgreements stage="onboarding" />;

      default:
        return <div>Please call support</div>;
    }
  };

  return (
    <div
      className={
        fundingStep == 'confirm'
          ? 'request-funding-wrapper-confirm'
          : fundingStep == 'sign-contract'
          ? 'request-funding-wrapper-sign-contract'
          : fundingStep == 'congrats'
          ? 'request-funding-wrapper-congrats'
          : fundingStep == 'funded' || fundingStep == 'failed'
          ? 'request-funding-wrapper-funded'
          : 'request-funding-wrapper'
      }
    >
      {getMainComponent()}
    </div>
  );
};
