import FooterButtons from '../../../Footer/footer-buttons';
import TitleSection from '../../../Header/title-section';
import FinchConnector from '../../../widgets/FinchConnector';
import { useContext, useEffect, useState } from 'react';
import './index.scss';
import refreshLogo from '../../../common-icons/refresh.png';
import { formatNumberAsDollars, isFeatureOn } from '../../../utils';
import { getClient } from '../../../api-utils/general-utils';
import LuxonUtils from '@date-io/luxon';
import PayroInput from '../../../widgets/PayroInput';
import PayroRadioButtonGroup from '../../../widgets/PayroRadioButtonGroup';
import Uploader from '../../../widgets/Uploader';
import Slider from '../../../widgets/RangeSlider';
import { getFiles, uploadFiles, deleteFile } from '../utils';

import Loader from '../../../widgets/Loader';
import RequestFundingTitle from '../request-funding-title';
import { PayrollInfo } from '../../../api-utils/generated-client';
import { MessageContext } from '../../../context';
import checkIcon from '../../../common-icons/checkIconGreen.png';
import mouse from '../../../common-icons/mouse.svg';
import arrowleft from '../../../common-icons/back-arrow.svg';
import PayroButton from '../../../widgets/PayroButton';
import { getCurrentAuthUser } from '../../../auth/utils/auth-utils';
import ActualSignature from '../../../pages/Application/SignAgreements/ActualSignature';
import {
  GetAccountDto,
  Opportunity,
  UserObject,
} from '../../../api-utils/generated-client';
import {
  getSignatureDisclaimer,
  DocumentSection,
} from '../../../pages/Application/SignAgreements/document-utils';
import {
  opportunityState,
  userInfoState,
} from '../../../recoil-state/general-states';
import {
  documentSectionsState,
  docVersionState,
  isNextButtonDisabledState,
  showDocsState,
  signatureDataUrlState,
  signedDocsState,
} from '../../../recoil-state/application-stage-states';
import { fundingStepState } from '../../../recoil-state/request-funding-states';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
} from 'recoil';
import {
  finchPayrollInfoState,
  isThereFutureFinchPayroll,
} from '../../../recoil-state/finch-states';
import {
  dealDraftState,
  sectionState,
  uploadedFilesState,
} from '../../../recoil-state/request-funding-states';
import { accountRecordState } from '../../../recoil-state/general-states';

//gets from api in for "2021-04-15"
//needs to be stored in same form, but datepicker sets as date object and needs to be initialized as date object
interface VerifyPayrollProps {
  type?: string;
  stage?: 'onboarding' | undefined;
  mcaAccount?: boolean;
  //userAddressState ?: string;
  IsRepeatFunding?: boolean;
}
export default (props: VerifyPayrollProps) => {
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const [accountRecord, setAccountRecord] = useRecoilState(
    accountRecordState,
  );
  const section = useRecoilValue(sectionState);

  const finchFeatureOn: boolean = isFeatureOn('Finch');
  const [uploadedFiles, setUploadedFiles] = useRecoilState(
    uploadedFilesState,
  );
  const [SignedAch, setSignedAch] = useState(false);

  const [loadedRepayment, setLoadedRepayment] =
    useState<boolean>(false);
  const [finishedloadingFinch, setFinishedLoadingFinch] =
    useState<boolean>(finchFeatureOn ? false : true);
  const [fundAmountSelection, setFundAmountSelection] = useState<
    'full' | 'other'
  >();
  const [fundingStep, setFundingStep] =
    useRecoilState(fundingStepState);
  const [showLoader, setShowLoader] = useState(false);
  const [showagreementLoader, setshowagreementLoader] =
    useState(false);
  const [showDocs, setShowDocs] = useRecoilState(showDocsState);
  const [signatureDataUrl, setSignaturedataUrl] = useRecoilState<any>(
    signatureDataUrlState,
  );
  const [documentSections, setDocumentSections] = useRecoilState<
    DocumentSection[]
  >(documentSectionsState);
  const [userFullName, setUserFullName] = useState<string>();
  const opportunities = useRecoilValue(opportunityState);
  const [mostRecentOpportunity, setMostRecentOpportunity] =
    useState<Opportunity>();
  const [docVersion, setDocVersion] =
    useRecoilState<string>(docVersionState);
  const [gotToEndOfText, setGotToEndOfText] =
    useState<boolean>(false);
  const [userInfo, setUserInfo] =
    useRecoilState<Partial<UserObject>>(userInfoState);
  const [signedDocs, setSignedDocs] =
    useRecoilState<boolean>(signedDocsState);
  // const [statements, setStatements] = useState(false);
  const AccountInfo = useRecoilValue(accountRecordState);
  // const [showUploader, setShowUploader] = useState<boolean>(true);
  // const [isThereFuturePayroll, setIsThereFuturePayroll] =
  // useRecoilState(isThereFutureFinchPayroll);
  // const [isAgreementLoading,setIsAgreementLoading] = useState<boolean>(false)
  const uploadTheFiles = async (fileToUpload: any[]) => {
    const client = await getClient();
    if (client) {
      const uploadRes = await uploadFiles(fileToUpload, client);

      if (uploadRes == 'fail') {
        messageContext.addMessage({
          level: 'error',
          message: 'Only PDF uploads are valid',
        });
      }

      const gottenFiles = await getFiles(client);
      setUploadedFiles(gottenFiles);
    }
  };

  const getNewUploadedFiles = () =>
    getClient().then((client) => {
      if (!client) {
        return;
      }
      getFiles(client).then((gottenFiles) =>
        setUploadedFiles(gottenFiles),
      );
    });

  const messageContext = useContext(MessageContext);
  useEffect(() => {
    const getOpportunitiesAndAccountInfo = async () => {
      setshowagreementLoader(true);
      const client = await getClient();
      if (!client) {
        return;
      }
      if (
        userInfo.document_signature_hash &&
        userInfo.document_signature_hash.length > 0
      ) {
        setSignedDocs(true);
      }
      // console.log('opportunities===========>',opportunities)
      if (!opportunities) return;
      setMostRecentOpportunity(opportunities[0]);

      const theDocSectionsRes =
        await client.documentsControllerGenerateCashAdvanceContract();

      const theDocSections = theDocSectionsRes.data
        .contractInfo as DocumentSection[];
      setDocumentSections(theDocSections);
      setDocVersion(theDocSectionsRes.data.documentVersion);
      const authUser = await getCurrentAuthUser();
      authUser.attributes
        ? await setUserFullName(
            `${authUser.attributes.given_name}  ${authUser.attributes.family_name}`,
          )
        : '';
      setshowagreementLoader(false);
    };
    // if(!isAgreementLoading){
    // setIsAgreementLoading(true)
    getOpportunitiesAndAccountInfo();
    // }
  }, []);

  if (!AccountInfo || !mostRecentOpportunity) {
    return <Loader message={'Please wait...'} />;
  }
  // else{
  //   console.log('Section 1')
  // }

  if (showLoader) {
    return <Loader message={'Please wait, submitting document'} />;
  }
  // else{
  //   console.log('Section 2')
  // }
  if (showagreementLoader) {
    return <Loader message={'Loading Agreement'} />;
  }
  // else{
  //   console.log('Section 3')
  // }

  const handleScroll = (element: any) => {
    const target = element.target;
    if (
      target.scrollHeight - target.scrollTop - 50 <=
      target.clientHeight
    ) {
      setGotToEndOfText(true);
      // setSignedAch(true)
    }
  };
  const confirmSignDoc = async () => {
    setShowLoader(true);
    const apiClient = await getClient();
    if (!apiClient) {
      return;
    }
    // await apiClient.documentsControllerCashAdvanceSignDocuments({
    //   signature: signatureDataUrl,
    //   documentVersion: docVersion as string,
    // });
    setShowLoader(false);
    setFundingStep('confirm');
  };
  const theDocsPs = documentSections.map((sec) => {
    switch (sec.theTag) {
      case 'h1':
        return <h1 key={Math.random()}>{sec.theText}</h1>;
      case 'h2':
        return <h2 key={Math.random()}>{sec.theText}</h2>;
      case 'h3':
        return <h3 key={Math.random()}>{sec.theText}</h3>;
      case 'h4':
        return <h4 key={Math.random()}>{sec.theText}</h4>;
      case 'h5':
        return <h5 key={Math.random()}>{sec.theText}</h5>;
      case 'p':
        return <p key={Math.random()}>{sec.theText}</p>;
      case 'h6':
        return <h6 key={Math.random()}>{sec.theText}</h6>;
    }
  });

  // const signHandler = async () => {
  //   setSignedDocs(true);
  //   setShowDocs(false);

  //   const client = await getClient();
  //   if (!client) {
  //     return;
  //   }
  //   setShowLoader(true);
  //   await client.documentsControllerCashAdvanceSignDocuments({
  //     signature: signatureDataUrl,
  //     documentVersion: docVersion as string,
  //   });
  //   if (props.stage == 'onboarding') {
  //     setFundingStep('funding-amount');
  //   } else {
  //     window.location.reload();
  //   }
  // };
  // console.log('docVersionhere',docVersion)
  // console.log('signatureDataUrlhere',signatureDataUrl)
  // console.log('dealRecordhere',dealRecord)
  // const userRequestsTooMuchFunding = dealRecord.funding_amount && dealRecord.funding_amount > (accountRecord.credit_amount_available as number);

  return (
    <>
      <div
        className={props.IsRepeatFunding ? 'repeat-advance' : ''}
        id="verify-payroll"
      >
        <RequestFundingTitle
          section={section}
          title={
            props.type === 'increase-credit'
              ? 'Verify Last Payroll'
              : 'Payroll Funding agreement'
          }
          subtitle="Read and sign merchant cash advance agreement"
          sectionNumber={
            props.type == 'increase-credit'
              ? undefined
              : section == 'onboarding'
              ? 4
              : section == 'more-funding'
              ? 4
              : 4
          }
        />

        {/* {finchFeatureOn && (
          <FinchConnector
            associatedPhase="repayment"
            refresh="yes"
            // level='error'
            // message='A future payroll must be manually uploaded in order to proceed.'
            isConnectedCallback={async () => {
              // props.setGotPayrollInfoFromFinch(true)
              setFinishedLoadingFinch(true);

              if (isThereFuturePayroll == 'yes') {
                //meaning there's a future payroll so manual upload isn't needed

                setShowUploader(false);
              }
              if (isThereFuturePayroll == 'no') {
                setShowUploader(true);
              }
            }}
            isDisconnectedCallback={() => {
              setFinishedLoadingFinch(true);
            }}
            onDisconnect={() => {
              setIsThereFuturePayroll(undefined);
              messageContext.clearMessages();
              setShowUploader(true);
            }}

            // broadcastPayrollInfo={setFinchPayrollInfo}
          />
        )} */}

        {/* {!showUploader && isThereFuturePayroll == 'yes' && (
          <>
            <h5 id="payroll-verified">
              Your Payroll Has Been Verified
            </h5>
          </>
        )} */}
        {/* {isThereFuturePayroll == 'no' && (
          <div>
            <p id="no-future-payroll">
              We are connected to your payroll system, but we don't see
              any payroll with a date that has not passed. Some payroll
              companies may take up to 24 hours to post this info.{' '}
              <br />
              <b>Please upload your payroll statement manually.</b>
            </p>
          </div>
        )} */}

        <>
          <div className="agreementheader">
            <span>
              A signed contract is required for each funding
              transaction
            </span>
          </div>
          {!showagreementLoader && (
            <div id="contract-outer-container" className="no-border">
              <div id="contract-wrapper">
                <div
                  id="contract-text"
                  onScroll={(e) => handleScroll(e)}
                >
                  {theDocsPs}
                </div>

                <div id="force-load-of-font">- - </div>

                {!gotToEndOfText && (
                  <div>
                    <p id="scroll-instructions">
                      <span className="bfr-img">
                        <img src={mouse} />
                      </span>
                      {signedDocs
                        ? 'Scroll and read the document to sign'
                        : 'Scroll and read the document to sign'}{' '}
                    </p>
                  </div>
                )}

                {gotToEndOfText && (
                  <div>
                    <div
                      id="signature-wrapper"
                      className="rounded-corner-section"
                    >
                      <p id="your-signature-label">
                        Your Generated Signature
                      </p>
                      <ActualSignature
                        fullName={userFullName as string}
                        getDataUrlCb={(theUrl: any) => {
                          setSignaturedataUrl(theUrl);
                        }}
                      />
                    </div>
                    {/* <p id="signature-disclaimer">
                        {getSignatureDisclaimer(
                          AccountInfo.legal_name as string,
                        )}{' '}
                      </p> */}
                    {/* <PayroButton
                        variant={signedDocs ? 'green' : 'green-agree'}
                        startIcon={signedDocs ? checkIcon : false}
                        disabled={
                          signedDocs
                            ? true
                            : gotToEndOfText
                            ? false
                            : true
                        }
                        customHeight="50px"
                        customWidth="width-200"
                        centered={true}
                        onClick={() =>
                          signHandler().then((res) => console.log(res))
                        }
                      >
                        {signedDocs
                          ? 'Your Signature Has Been Recorded'
                          : 'I Agree & Sign'}
                      </PayroButton> */}

                    {/* <div className="content-center ach-header-text">
                        
                        <span className="ach-span">I agree to the Payro Terms and Conditions</span>
                      </div> */}

                    <div className="sign-agrementbotom">
                      <div className="agrementbotom">
                        <input
                          type="checkbox"
                          onChange={() => setSignedAch(!SignedAch)}
                          className="grey-square"
                          // className="checkbox-design"
                        />
                        <span id="clickingagrement">
                          By clicking the “agree” button, you agree to
                          use this generated
                          <br />
                          signature as the official authorization of
                          the document
                        </span>
                      </div>
                      {props.IsRepeatFunding ? (
                        <PayroButton
                          variant={
                            SignedAch ? 'green' : 'green-agree'
                          }
                          disabled={!SignedAch}
                          customHeight="50px"
                          customWidth="width-200"
                          centered={true}
                          onClick={() => confirmSignDoc()}
                        >
                          {'I agree & sign this document'}
                        </PayroButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </div>
      {/* <div className='agreement'>
        <button className='Agreebutton' onClick={() =>signHandler().then((res) => console.log(res))} >
            I Agree & Sign Document
          </button>
        </div> */}

      {!props.IsRepeatFunding ? (
        <FooterButtons
          nextDisabled={false}
          mca_account={props.mcaAccount}
          // userAddressState={props.userAddressState}
          SignAgreement={SignedAch}
          signatureDataUrl={signatureDataUrl}
          documentVersion={docVersion as string}
        />
      ) : (
        <div id="footer-button-wrapper" className={''}>
          <PayroButton
            onClick={() => {
              setFundingStep('funding-amount');
            }}
            className={'accent-background-color new-btn-c'}
          >
            <img src={arrowleft} />
            Back
          </PayroButton>
        </div>
      )}
    </>
  );
};

// const getFundingLimitForThisPayroll = (
//   approvedCreditLimit: number | undefined,
//   payrollAmount: number | undefined,
// ) => {
//   if (!approvedCreditLimit || !payrollAmount) {
//     return 0;
//   }
//   if (approvedCreditLimit > payrollAmount) {
//     return payrollAmount;
//   } else {
//     return approvedCreditLimit;
//   }
// };
