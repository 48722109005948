import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import './index.scss';
import ProgressCircle from './ProgressCircle.svg';
import ProgressCircleLock from './ProgressCircleLock.svg';
import InProgress from './InProgress.svg';
import { applicationStageSectionNames } from '../../../constants';
import { accountRecordState } from '../../../recoil-state/general-states';
import phone from '../../../common-icons/phone.png';
import chat from '../SectionMarker/chat-icon.png';
import { GetAccountDto } from '../../../api-utils/generated-client';

export type SectionMarkerSection =
  | 'CompanyInfo'
  | 'PayrollInfo'
  | 'BankStatements'
  | 'SignAgreements';

interface SectionMarkerProps {
  currentSection: SectionMarkerSection;
  furthestSection?: SectionMarkerSection;
}

const SectionMarker = (props: SectionMarkerProps) => {
  let allSections: Array<any> = [];

  let indexOfSelectedSection = applicationStageSectionNames.findIndex(
    (x) => x.apiRef == props.currentSection,
  );
  const [AccountDetail, setAccountDetail] =
    useRecoilState<GetAccountDto>(accountRecordState);

  let indexOfFurthestSection = applicationStageSectionNames.findIndex(
    (x) => x.apiRef == props.furthestSection,
  );
  if (!indexOfFurthestSection || indexOfFurthestSection == -1)
    indexOfFurthestSection = 0;
  if (!indexOfSelectedSection || indexOfSelectedSection == -1)
    indexOfSelectedSection = 0;

  for (let i = 0; i < applicationStageSectionNames.length; i++) {
    allSections.push(
      <div
        key={'section-marker-' + i}
        className={
          'section-marker' +
          (indexOfFurthestSection > i ? ' checked' : '') +
          (props.currentSection ==
          applicationStageSectionNames[i].apiRef
            ? ' selected'
            : '')
        }
      >
        <div className="section-marker-text">
          <p className="section-marker-title">
            {AccountDetail && AccountDetail.mca_account
              ? applicationStageSectionNames[i].title ==
                'Payro Agreement'
                ? 'Terms & Conditions'
                : applicationStageSectionNames[i].title
              : applicationStageSectionNames[i].title}
          </p>
          <p className="section-marker-subtitle">
            {AccountDetail && AccountDetail.mca_account
              ? applicationStageSectionNames[i].subtitle ==
                'Sign docs'
                ? 'Accept terms'
                : applicationStageSectionNames[i].subtitle
              : applicationStageSectionNames[i].subtitle}
          </p>
        </div>

        {
          <img
            src={
              indexOfFurthestSection > i
                ? ProgressCircle
                : indexOfFurthestSection == i
                ? InProgress
                : ProgressCircleLock
            }
          ></img>
        }
      </div>,
    );
  }

  return (
    <>
      <div id="section-marker-container">{allSections}</div>
      <div id="need-help-container">
        <p className="need-help-text">Need Help?</p>
        <div className="need-help-info-wrapper-phone">
          <img src={phone} />
          <p className="phone-number"> 1-833-271-4499</p>
        </div>

        <p className="monday-fri">Monday-Fri 9am - 5pm EST</p>
        <div className="need-help-info-wrapper">
          <img src={chat} />

          <p className="email-add">
            {' '}
            processing@payrofinance.com
            {/* <a href="mailto:processing@payrofinance.com">
             
            </a> */}
          </p>
        </div>
      </div>
      <div id="data-encrypted-container-home-new">
        <img
          className="lock-icon"
          src={ProgressCircleLock}
          alt="image"
        />
        <p className="data-encrypted-text"> End-to-end encryption.</p>
      </div>
    </>
  );
};

export default SectionMarker;
