import { useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useScript } from 'use-script';
import {
  BankAccount,
  UpdateAccountDto,
  UpdateBankAccountDto,
} from '../../api-utils/generated-client';
import {
  bankAccountsState,
  bankAccountToUpdateState,
  chaseBankAccountsArrayState,
  chaseBankAccountToUpdateState,
  isAllBankInfoValidState,
  needsManualFormState,
} from '../../recoil-state/request-funding-states';
import PayroInput from '../PayroInput';
import checkCircle from '../FinchConnector/check-circle.png';
import InfoIcon from '../../common-icons/info-icon.svg';
import { isNextButtonDisabledState } from '../../recoil-state/application-stage-states';
import RequestFundingTitle from '../../pages/RequestFunding/request-funding-title';
import { useHistory } from 'react-router-dom';
import './index.css';
import { getClient } from '../../api-utils/general-utils';

const ManualBankForm = () => {
  let history = useHistory();
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  const [chaseBankAccountToUpdate, setChaseBankAccountToUpdate] =
    useRecoilState<UpdateBankAccountDto>(
      chaseBankAccountToUpdateState,
    );

  const [needsManualForm, setNeedsManualForm] = useRecoilState(
    needsManualFormState,
  );
  const [isNextDisabled, setIsNextDisabled] = useRecoilState(
    isNextButtonDisabledState,
  );
  const [reenterRoutingNumber, setReenterRoutingNumber] =
    useState<string>('');
  const [reenterAccountNumber, setReenterAccountNumber] =
    useState<string>('');
  const isNineNumbers = /^[0-9]{9}$/;
  const isOnlyNumbers = /^[0-9]/;

  if (
    !chaseBankAccountToUpdate ||
    !chaseBankAccountToUpdate.bank_account_number ||
    !chaseBankAccountToUpdate.bank_routing_number ||
    reenterAccountNumber == '' ||
    reenterRoutingNumber == ''
  ) {
    setIsNextDisabled(true);
  } else {
    setIsNextDisabled(false);
  }
  return (
    <>
      <div key={chaseBankAccountToUpdate.uuid}>
        <RequestFundingTitle
          section={'manual-bank'}
          title="Confirm Chase Bank Info"
          subtitle="For added security with Chase, your bank requires you to re-enter your 
          checking account number and routing number manually. 
          Plaid only shares with us the last 4 digits of your account."
        />
        <div
          id="chase-details-wrapper"
          className="connected-account-container-chase "
        >
          <>
            <div
              key={chaseBankAccountToUpdate.uuid}
              id="routing-number-section"
            >
              <div className="bank-info-input-wrapper left-side">
                <PayroInput
                  label={` ${chaseBankAccountToUpdate.bank_name}
                        ***${chaseBankAccountToUpdate.account_last_four}
                       
                        Enter Full Routing Number`}
                  value={chaseBankAccountToUpdate.bank_routing_number}
                  wrapperAdditionalClasses="full-width-left-side"
                  onBlurFunction={(e: any) => e.stopPropagation()}
                  onChange={async (e) => {
                    await setChaseBankAccountToUpdate({
                      ...chaseBankAccountToUpdate,
                      bank_routing_number: e.toString(),
                    });

                    //saveBankUpdate();
                  }}
                  error={
                    !chaseBankAccountToUpdate.bank_routing_number ||
                    (chaseBankAccountToUpdate.bank_routing_number &&
                      chaseBankAccountToUpdate.bank_routing_number
                        ?.length < 9) ||
                    !isNineNumbers.test(
                      chaseBankAccountToUpdate.bank_routing_number,
                    ) ||
                    !isOnlyNumbers.test(
                      chaseBankAccountToUpdate.bank_routing_number,
                    )
                  }
                  helperText={
                    chaseBankAccountToUpdate.bank_routing_number &&
                    chaseBankAccountToUpdate.bank_routing_number
                      ?.length < 9
                      ? 'A 9 digit number is required'
                      : ''
                  }
                  placeholder="Enter Routing Number"
                />
              </div>{' '}
              <div className="bank-info-input-wrapper right-side">
                <PayroInput
                  wrapperAdditionalClasses="full-width-right-side"
                  label=""
                  placeholder="Retype Routing Number"
                  value={reenterRoutingNumber}
                  onChange={(e) => {
                    setReenterRoutingNumber(e.toString());
                  }}
                  onBlurFunction={(e: any) => e.stopPropagation()}
                  error={
                    chaseBankAccountToUpdate.bank_routing_number !=
                    reenterRoutingNumber
                  }
                  helperText={
                    chaseBankAccountToUpdate.bank_routing_number !=
                    reenterRoutingNumber
                      ? 'Numbers must match'
                      : ''
                  }
                  onCopy={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              </div>
            </div>
            <div id="account-number-section">
              <div className="bank-info-input-wrapper left-side">
                <PayroInput
                  label={` ${chaseBankAccountToUpdate.bank_name}
                             ***${chaseBankAccountToUpdate.account_last_four}
                            
                             Enter Full Account Number`}
                  placeholder="Enter Account Number"
                  value={chaseBankAccountToUpdate.bank_account_number}
                  onChange={async (e) => {
                    await setChaseBankAccountToUpdate({
                      ...chaseBankAccountToUpdate,
                      bank_account_number: e.toString(),
                    });

                    // saveBankUpdate();
                  }}
                  error={
                    !chaseBankAccountToUpdate.bank_account_number ||
                    (chaseBankAccountToUpdate.bank_account_number &&
                      chaseBankAccountToUpdate.bank_account_number
                        ?.length < 9) ||
                    !isOnlyNumbers.test(
                      chaseBankAccountToUpdate.bank_account_number,
                    )
                  }
                  helperText={
                    chaseBankAccountToUpdate.bank_account_number &&
                    chaseBankAccountToUpdate.bank_account_number
                      ?.length < 5
                      ? 'A valid number is required'
                      : ''
                  }
                  onCopy={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              </div>
              <div className="bank-info-input-wrapper right-side">
                <PayroInput
                  label=""
                  placeholder="Retype Account Number"
                  value={reenterAccountNumber}
                  onChange={(e) => {
                    setReenterAccountNumber(e.toString());
                  }}
                  onBlurFunction={(e: any) => e.stopPropagation()}
                  error={
                    chaseBankAccountToUpdate.bank_account_number !=
                    reenterAccountNumber
                  }
                  helperText={
                    chaseBankAccountToUpdate.bank_account_number !=
                    reenterAccountNumber
                      ? 'Numbers must match'
                      : ''
                  }
                  onCopy={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e: any) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              </div>{' '}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default ManualBankForm;
