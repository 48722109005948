import { useContext, useEffect, useState } from 'react';
import './index.scss';
import { getClient } from '../../../api-utils/general-utils';
import Uploader from '../../../widgets/Uploader';
import Slider from '../../../widgets/RangeSlider';
import {
  getFiles,
  uploadFiles,
  deleteFile,
} from '../../RequestFunding/utils';
import { MessageContext } from '../../../context';
import { useRecoilState } from 'recoil';
import infoIcon from '../../../common-icons/info-icon.svg';
import { uploadedFilesState } from '../../../recoil-state/request-funding-states';
import { checkedNotUploadingPayrollState } from '../../../recoil-state/finch-states';
interface isUnderwriting {
  isUnderwriting?: boolean;
}

export default (props: isUnderwriting) => {
  const [uploadedFiles, setUploadedFiles] = useRecoilState(
    uploadedFilesState,
  );
  const [checkedNotUploadingPayroll, setCheckedNotUploadingPayroll] =
    useRecoilState<boolean>(checkedNotUploadingPayrollState);

  const uploadTheFiles = async (fileToUpload: any[]) => {
    const client = await getClient();

    if (client) {
      await uploadFiles(fileToUpload, client, props.isUnderwriting);
      const gottenFiles = await getFiles(
        client,
        props.isUnderwriting,
      );
      setUploadedFiles(gottenFiles);
    }
  };

  const getNewUploadedFiles = () =>
    getClient().then((client) => {
      if (!client) {
        return;
      }
      getFiles(client, props.isUnderwriting).then(
        (gottenFiles: any) => setUploadedFiles(gottenFiles),
      );
    });

  useEffect(() => {
    getNewUploadedFiles();
  }, []);
  // console.log(
  //   'checkedNotUploadingPayroll',
  //   checkedNotUploadingPayroll,
  // );
  const messageContext = useContext(MessageContext);

  return (
    <div id="verify-payroll">
      <p className="verify-last-payroll-title">
        Upload your payroll summary
      </p>
      <>
        <Uploader
          inputChangeHandler={async (files: any) => {
            for (let i = 0; i < files.length; i++) {
              if (files[i].size > 10_000_000) {
                messageContext.addMessage({
                  level: 'error',
                  message: 'File Size is limited to 10MB',
                });
                return;
              }
            }
            await uploadTheFiles(files);
          }}
          instructionsText="Upload Payroll Summary"
          uploadedFiles={uploadedFiles}
          getLatestUploadedFiles={async () => {
            await getNewUploadedFiles();
          }}
          deleteFile={(fileName) => {
            getClient().then((client) => {
              if (client) {
                deleteFile(
                  fileName,
                  client,
                  props.isUnderwriting,
                ).then((res) => {
                  getNewUploadedFiles().then((res: any) => {});
                });
              }
            });
          }}
          isUnderwriting={
            props.isUnderwriting ? props.isUnderwriting : false
          }
        />
        {props.isUnderwriting && uploadedFiles.length < 1 ? (
          <>
            <div className="checkboxdata-message">
              <input
                type="checkbox"
                checked={checkedNotUploadingPayroll}
                onChange={() =>
                  setCheckedNotUploadingPayroll(
                    !checkedNotUploadingPayroll,
                  )
                }
                className="checkbox-design"
              />
              <p className="checkboxdata">
                I'll skip this step for now and submit the report
                later
              </p>
            </div>
            {checkedNotUploadingPayroll ? (
              <div className="message-to-user-and-icon-wrapper">
                <img src={infoIcon} />
                <p className="message-to-user">
                  Skipping this step will delay the underwriting
                  process
                </p>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </>
    </div>
  );
};
