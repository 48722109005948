import { useRecoilState } from 'recoil';
import PayroInput from '../../../widgets/PayroInput';
import './index.scss';
import { dealDraftState } from '../../../recoil-state/request-funding-states';
import { getClient } from '../../../api-utils/general-utils';

export default () => {
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);

  const savePayrollAmount = async (payrollAmount: number) => {
    console.log('DraftDeal', dealRecord);
    console.log(
      'actual payroll amount',
      dealRecord?.actual_payroll_amount,
    );
    await setDealRecord({
      ...dealRecord,
      funding_amount: dealRecord.funding_amount,
      actual_payroll_amount: payrollAmount,
    });
    const client = await getClient();
    if (!client) {
      return;
    }
    await client
      .dealsControllerUpdate({
        funding_amount: dealRecord.funding_amount,
        actual_payroll_amount: payrollAmount,
      })
      .then(() => {
        console.log('deal', dealRecord);
      });
  };
  const userRequestsTooLittleFunding =
    dealRecord?.actual_payroll_amount &&
    dealRecord?.actual_payroll_amount < 5000;

  return (
    <>
      <div className="total-payroll-amount-wrapper">
        <PayroInput
          onBlurFunction={(e: any) => {
            savePayrollAmount(parseFloat(e.target.value)).then(
              () => {},
            );
          }}
          isOnlyNumbers
          placeholder="0.00"
          value={dealRecord.actual_payroll_amount ?? 0}
          onChange={(eventValue: any) => {
            if (eventValue) {
              setDealRecord({
                ...dealRecord,
                actual_payroll_amount: parseFloat(eventValue),
              });
            }
          }}
          label="Upcoming Payroll Amount:"
          subLabel={'Including taxes and fees.'}
          variant="white"
          type="currency"
          error={userRequestsTooLittleFunding ? true : false}
          helperText={
            userRequestsTooLittleFunding
              ? 'A minimum of $5,000 is required'
              : ''
          }
        />
      </div>
    </>
  );
};
