import { useHistory } from 'react-router-dom';
import { getCurrentAuthUser } from '../../../auth/utils/auth-utils';
import { useEffect, useState } from 'react';
import TitleSection from '../../../Header/title-section';
import { getClient } from '../../../api-utils/general-utils';
import parse from 'html-react-parser';
import {
  GetAccountDto,
  Opportunity,
  UserObject,
} from '../../../api-utils/generated-client';
import {
  getSignatureDisclaimer,
  DocumentSection,
} from './document-utils';
import Loader from '../../../widgets/Loader';
import './index.scss';
import PayroButton from '../../../widgets/PayroButton';
import ActualSignature from './ActualSignature';

import checkIcon from '../../../common-icons/checkIconGreen.png';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  documentSectionsState,
  docVersionState,
  isNextButtonDisabledState,
  showDocsState,
  signatureDataUrlState,
  signedDocsState,
  submitApplicationState,
} from '../../../recoil-state/application-stage-states';
import {
  accountRecordState,
  opportunityState,
  userInfoState,
} from '../../../recoil-state/general-states';
import { fundingStepState } from '../../../recoil-state/request-funding-states';

interface SignAgreementsProps {
  stage?: 'onboarding' | undefined;
  isTerm?: Function;
}

function SignAgreements(props: SignAgreementsProps) {
  const history = useHistory();

  const [mostRecentOpportunity, setMostRecentOpportunity] =
    useState<Opportunity>();
  const [accountInfo, setAccountInfo] = useState<GetAccountDto>();
  const [gotToEndOfText, setGotToEndOfText] =
    useState<boolean>(false);
  const [signedDocs, setSignedDocs] =
    useRecoilState<boolean>(signedDocsState);
  const [checkedAgreeToSoftPull, setCheckedAgreeToSoftPull] =
    useState<boolean>(false);
  const [signatureDataUrl, setSignaturedataUrl] = useRecoilState<any>(
    signatureDataUrlState,
  );
  const [submitApplication, setSubmitApplication] = useRecoilState(
    submitApplicationState,
  );

  const [termshceck, setTermsCheck] = useState(false);
  const [softcreditcheck, setSoftcreditcheck] = useState(false);
  const [documentSections, setDocumentSections] = useRecoilState<
    DocumentSection[]
  >(documentSectionsState);
  const [userFullName, setUserFullName] = useState<string>();
  const [showDocs, setShowDocs] = useRecoilState(showDocsState);
  const [docVersion, setDocVersion] =
    useRecoilState<string>(docVersionState);
  const [showLoader, setShowLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [userInfo, setUserInfo] =
    useRecoilState<Partial<UserObject>>(userInfoState);
  const [isNextDisabled, setIsNextDisabled] = useRecoilState(
    isNextButtonDisabledState,
  );
  const [fundingStep, setFundingStep] =
    useRecoilState(fundingStepState);
  const AccountInfo = useRecoilValue(accountRecordState);
  const opportunities = useRecoilValue(opportunityState);

  useEffect(() => {
    // console.log('ChangedsubmitApplication',submitApplication)
  }, [submitApplication]);
  // console.log('submitApplication',submitApplication)

  useEffect(() => {
    const getOpportunitiesAndAccountInfo = async () => {
      const client = await getClient();
      if (!client) {
        return;
      }

      if (
        userInfo.document_signature_hash &&
        userInfo.document_signature_hash.length > 0
      ) {
        setSignedDocs(true);
      }

      if (!opportunities) return;
      setMostRecentOpportunity(opportunities[0]);
      const theDocSectionsRes =
        await client.documentsControllerGenerateDocuments();
      const theDocSections = theDocSectionsRes.data
        .contractInfo as DocumentSection[];
      setDocumentSections(theDocSections);
      setDocVersion(theDocSectionsRes.data.documentVersion);
      const authUser = await getCurrentAuthUser();
      setUserFullName(
        `${authUser.attributes.given_name}  ${authUser.attributes.family_name}`,
      );
    };

    getOpportunitiesAndAccountInfo();
  }, []);

  if (!AccountInfo || !mostRecentOpportunity) {
    return <Loader />;
  }

  if (showLoader) {
    return (
      <Loader
        message={
          props.stage == 'onboarding'
            ? 'Saving'
            : 'Submitting your application'
        }
      />
    );
  }

  const handleScroll = (element: any) => {
    const target = element.target;
    if (
      target.scrollHeight - target.scrollTop - 50 <=
      target.clientHeight
    ) {
      setGotToEndOfText(true);
    }
  };

  const theDocsPs = documentSections.map((sec) => {
    switch (sec.theTag) {
      case 'h1':
        return <h1 key={Math.random()}>{sec.theText}</h1>;
      case 'h3':
        return <h3 key={Math.random()}>{sec.theText}</h3>;
      case 'h5':
        return <h5 key={Math.random()}>{sec.theText}</h5>;
      case 'p':
        return <p key={Math.random()}>{sec.theText}</p>;
    }
  });
  var termconditions = '<h1 class="title">Terms and Conditions </h1>';
  termconditions +=
    '<p>By signing below, each of the listed businesses and business owner/officer (individually and collectively, “you”) authorize Payro Finance and each of its representatives, successors, assigns and designates (“Recipients”) that may be involved with or acquire commercial loans or purchases of future receivables including Merchant Cash Advance transactions, including without limitation the application therefor (collectively, “Transactions”) to obtain consumer or personal, business and investigative reports and other information about you, including bank statements and credit card processor statements, from one or more consumer reporting agencies, and from other credit bureaus, banks, creditors and other third parties.';
  termconditions += '<h1 class="heading">Authorization:</h1>';
  termconditions +=
    '<p>Authorization for soft pulls: Each Merchant and each Owner understands that by signing this Agreement, they are providing ‘written instructions’ to PAYRO under the Fair Credit Reporting Act, authorizing PAYRO to obtain information from their personal credit profile or other information from Experian, TransUnion, and Equifax. Each Merchant and each Guarantor authorizes PAYRO to obtain such information solely to conduct a pre-qualification for credit decision making.</p>';
  termconditions +=
    '<p>You also authorize Payro Finance to transmit this application, along with any of the foregoing information obtained in connection with this application, to any or all Recipients for the ongoing purposes. You also consent to the release, by any creditor or financial institution, of any information relating to any of you, to Payro Finance and to each recipient, on its own behalf</p>';
  termconditions += '<h1 class="heading">Disclaimer:</h1>';
  termconditions +=
    "<p>Commercial Loan or Merchant Cash Advance approval is not guaranteed. Approvals are based upon Payro’s proprietary scoring and underwriting system's review of your credit, financial condition, other factors, and supporting documents or information you provide.</p>";
  termconditions +=
    '<p>The information provided through payro™ does not constitute legal, tax, financial or accounting advice, and should not be considered a substitute for obtaining competent personalized advice from a licensed professional. You should seek professional advice before making any decision that could affect the financial health of your business. To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person and business that seeks a business loan. What this means for you: When you apply for a loan, we will ask for your business name, address, and Tax Identification Number. We will also ask for your name, address, date of birth, and other information that will allow us to identify you.</p>';
  termconditions += '<h1 class="heading">Indemnification </h1>';
  termconditions +=
    '<p>You agree to indemnify us, our licensors, vendors, insurers, agents and commercial partners and our respective officers, directors, employees and agents from and against any and all claims, demands, liabilities, losses, damages, costs and expenses, including reasonable attorney’s fees, that arise from any content or data you post, transmit or link from on the Services, your misuse of the Services, including, but not limited to, your violation of these Terms, or your violation of any rights of any third party. THIS INDEMNIFICATION OBLIGATION SHALL SURVIVE THE TERMINATION OF THESE TERMS AND YOUR USE OF THE SERVICES.</p>';
  termconditions +=
    '<p>Governing Law: The laws of the State of Virginia as govern these Terms, without reference to its conflicts of law principles, except that the federal law of the United States shall apply to questions regarding the validity, infringement or enforceability of U.S. federal patent, copyright and trademark rights relating in any way to these Terms, the Website or the Services.</p>';
  termconditions += '<h1 class="heading">Miscellaneous Terms:</h1>';
  termconditions +=
    '<p>If any provision of these Terms are is held to be unenforceable, that provision will be removed and the remaining provisions will remain in full force. The failure of either you or us to require performance by the other party of any provision of these Terms will not affect the right to require performance at any time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a waiver of the provision itself.</p>';
  termconditions +=
    '<p>Payro Capital, LLC hereby declares and admit that any agreements entered into with any Jewish individual or any Jewish owned company, shall be structured as an Iska partnership. The Iska partnership shall be subject to the following terms:</p>';
  termconditions +=
    '<p>All profits and losses shall be shared equally between the investing and managing partners. The managing partner must verify any claim of loss through the testimony of two Halachacly acceptable witnesses in an Orthodox Jewish court of law. Any claim regarding the amount of profits generated by the joint venture must be verified with a solemn oath, administered by an Orthodox Jewish court of law or any other verification as seen fit by the above mentioned court. In addition, the investing partner may demand full access to all legal records, documents, financial statements or receipts that the investing partner deems necessary to verify the claims of the managing partners. In the event that it is Halachacly preferable, this Iska shall be structured as Kulo Pekadon. In the event of a claim of loss, the investing partner may demand a full and immediate payment of the remaining balance of this Iska.</p>';
  termconditions +=
    '<p>The managing partners obligate themselves to manage the Iska partnership in the manner deemed most beneficial to the investing partner. Any investment owned by the managing partners may be used to create this Iska partnership, provided that the venture is Halachacly permissible. The managing partner shall be paid one dollar for his/her services during the term of the Iska. It is agreed that if the managing partner pays the amount equal to the principal, fees, points, penalties, interest, plus any other payments called for by a contract signed by the two parties, as per the payment schedule of said contract, as payment for the investing partner’s share of profits, the investing partner shall waive his right to demand verification of the results of the investment. Any additional profits shall belong solely to the managing partner. These payments include the one-dollar token payment mentioned above.</p>';
  termconditions +=
    '<p>Any payments made that are not justified by this Iska agreement shall reduce the principal balance of this Iska. Future payments shall be increased accordingly to compensate for any discrepancy. Any mention of loans or interest shall be interpreted as an Iska and to payments mentioned above. This declaration is legally binding and may be enforced in any Jewish or civil court, even in the event that one of the parties was unaware of its existence. This agreement has been executed in an effective manner and with a proper Kinyan in a manner consistent affecting a halachic legal transfer and is not an “Asmachta”.</p>';
  // termconditions += '<h1 class="heading">Copyrights, Trademarks and Other Proprietary Rights</h1>';
  // termconditions += '<p>You agree that we retain all ownership rights in the Website and Services (including any tools and/or proprietary content made available through the Services) and that you do not receive any ownership rights or license rights, except as set forth herein, by accessing or using the Website or the Services or by consenting to these Terms. All trademarks, service marks and trade names are owned by Payro or other respective owners. The entire content of the Services and the Website, including but not limited to text, design, software, photography, video, graphics, music, sound, information and the selection, coordination, arrangement, and enhancement thereof, is protected under the copyright laws of the United States, international treaties and other intellectual property laws (including without limitation the copyright in the selection, coordination, arrangement and enhancement of all content).  </p>';
  // termconditions += '<h1 class="heading">Disclaimer of liability</h1>';
  // termconditions += '<p>We are under no obligation to become involved in any dispute that you have with other users of the Services, or any other third party. We disclaim all liability relating to any records or other content shared via the Services (“User Content”), including any error, virus, defamation, libel, obscenity or inaccuracy contained in any User Content, whether or not arising under the laws of copyright, libel, privacy or otherwise, any prohibited User Content and any other User Content. You are solely responsible for any damage (including without limitation to the Services) resulting from use (and/or submission) of any User Content and/or the Services (including without limitation disputes and incidents described in the preceding sections) and related transactions and/or occurrences.</p>';
  // termconditions += '<h1 class="heading">Linked Sites</h1>';
  // termconditions += '<p>The Services may integrate with and/or provide links to various other independent third-party products and/or services (“Linked Sites”) that may be of interest to you and are for your convenience only. We do not control and/or endorse such Linked Sites and are not responsible for their content nor are we responsible for the accuracy and/or reliability of any information, data, opinions, advice and/or statements contained within such Linked Sites. You will need to make your own independent judgment regarding your interaction with Linked Sites at your own risk. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each Linked Site that you visit. We reserve the right to terminate any link and/or linking program at any time in its sole and absolute discretion. We disclaim all warranties, express and implied, as to the accuracy, validity and legality and/or otherwise of any materials and/or information contained on such Linked Sites.</p>';
  // termconditions += '<h1 class="heading">Disclaimer of Warranties</h1>';
  // termconditions += '<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTIES OF ANY KIND, AND WE DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. FURTHERMORE, WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR CODE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</p>';
  // termconditions += '<h1 class="heading">Exclusive Remedy</h1>';
  // termconditions += '<p>Except as set forth in the “Limitations on Liability” section, if you are dissatisfied with the Services, your sole and exclusive remedy is to discontinue using the Services.</p>';
  // termconditions += '<h1 class="heading">Limitations on Liability</h1>';
  // termconditions += '<p>NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS OR OTHERWISE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PAYRO, ITS AGENTS, REPRESENTATIVES AND VENDORS WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY LOST OR CORRUPTED DATA OR CONTENT, ANY ERRORS CAUSED BY THE SERVICES AVAILABLE THROUGH THE WEBSITE, OR SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND ARISING OUT OF USE OF THE SERVICE OR ANY SUBJECT MATTER OF THESE ONLINE TERMS OF SERVICE, EVEN IF WE HAVE BEEN ADVISED OF OR COULD HAVE FORESEEN THE POSSIBILITY OF SUCH DAMAGES UNDER ANY THEORY OF LIABILITY. IN NO EVENT WILL WE HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY ARISING FROM YOUR USE OF THE SERVICES WHICH IN THE AGGREGATE IS MORE THAN $200.</p>';
  // termconditions += '<h1 class="heading">Indemnification</h1>';
  // termconditions += '<p>You agree to indemnify us, our licensors, vendors, insurers, agents and commercial partners and our respective officers, directors, employees and agents from and against any and all claims, demands, liabilities, losses, damages, costs and expenses, including reasonable attorney’s fees, that arise from any content or data you post, transmit or link from on the Services, your misuse of the Services, including, but not limited to, your violation of these Terms, or your violation of any rights of any third party. THIS INDEMNIFICATION OBLIGATION SHALL SURVIVE THE TERMINATION OF THESE TERMS AND YOUR USE OF THE SERVICES.</p>';
  // termconditions += '<h1 class="heading">Governing Law</h1>';
  // termconditions += '<p>The laws of the State of Virginia as govern these Terms, without reference to its conflicts of law principles, except that the federal law of the United States shall apply to questions regarding the validity, infringement or enforceability of U.S. federal patent, copyright and trademark rights relating in any way to these Terms, the Website or the Services.</p>';
  // termconditions += '<h1 class="heading">Miscellaneous Terms</h1>';
  // termconditions += '<p>Payro Capital, LLC hereby declares and admit that any agreements entered into with any Jewish individual or any Jewish owned company, shall be structured as an Iska partnership. The Iska partnership shall be subject to the following terms:</p>';
  // termconditions += '<p>All profits and losses shall be shared equally between the investing and managing partners.  The managing partner must verify any claim of loss through the testimony of two Halachacly acceptable witnesses in an Orthodox Jewish court of law. Any claim regarding the amount of profits generated by the joint venture must be verified with a solemn oath, administered by an Orthodox Jewish court of law or any other verification as seen fit by the above mentioned court. In addition, the investing partner may demand full access to all legal records, documents, financial statements or receipts that the investing partner deems necessary to verify the claims of the managing partners. In the event that it is Halachacly preferable, this Iska shall be structured as Kulo Pekadon.</p>';
  // termconditions += '<p>In the event of a claim of loss, the investing partner may demand a full and immediate payment of the remaining balance of this Iska.</p>';
  // termconditions += '<p>The managing partners obligate themselves to manage the Iska partnership in the manner deemed most beneficial to the investing partner. Any investment owned by the managing partners may be used to create this Iska partnership, provided that the venture is Halachacly permissible. The managing partner shall be paid one dollar for his/her services during the term of the Iska.</p>';
  // termconditions += '<p>It is agreed that if the managing partner pays the amount equal to the principal, fees, points, penalties, interest, plus any other payments called for by a contract signed by the two parties, as per the payment schedule of said contract, as payment for the investing partner’s share of profits, the investing partner shall waive his right to demand verification of the results of the investment. Any additional profits shall belong solely to the managing partner. These payments include the one-dollar token payment mentioned above.</p>';
  // termconditions += '<p>Any payments made that are not justified by this Iska agreement shall reduce the principal balance of this Iska. Future payments shall be increased accordingly to compensate for any discrepancy.</p>';
  // termconditions += '<p>Any mention of loans or interest shall be interpreted as an Iska and to payments mentioned above. This declaration is legally binding and may be enforced in any Jewish or civil court, even in the event that one of the parties was unaware of its existence. This agreement has been executed in an effective manner and with a proper Kinyan in a manner consistent affecting a halachic legal transfer and is not an “Asmachta”.</p>';
  // // termconditions += '<h1 class="heading">If any provision of these Terms are is held to be unenforceable, that provision will be removed and the remaining provisions will remain in full force. The failure of either you or us to require performance by the other party of any provision of these Terms will not affect the right to require performance at any time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a waiver of the provision itself.</h1>';
  // termconditions += '<p>If any provision of these Terms are is held to be unenforceable, that provision will be removed and the remaining provisions will remain in full force. The failure of either you or us to require performance by the other party of any provision of these Terms will not affect the right to require performance at any time in the future; nor will the waiver by either party of a breach of any provision be taken or held to be a waiver of the provision itself.</p>';
  // termconditions += '<h1 class="heading">Authorization for soft pulls:</h1>';
  // termconditions += '<p>Each Merchant and each Owner understands that by signing this Agreement, they are providing ‘written instructions’ to PAYRO under the Fair Credit Reporting Act, authorizing PAYRO to obtain information from their personal credit profile or other information from Experian, TransUnion, and Equifax. Each Merchant and each Guarantor authorizes PAYRO to obtain such information solely to conduct a pre-qualification for credit.</p>';

  const signHandler = async () => {
    setSignedDocs(true);
    setShowDocs(false);

    const client = await getClient();
    if (!client) {
      return;
    }
    setShowLoader(true);
    await client.documentsControllerSignDocuments({
      signature: signatureDataUrl,
      documentVersion: docVersion as string,
    });
    if (props.stage == 'onboarding') {
      setFundingStep('funding-amount');
    } else {
      window.location.reload();
    }
  };
  const selectTerms = (e: any) => {
    setTermsCheck(!termshceck);
    if (!termshceck == true && softcreditcheck == true) {
      props.isTerm && props.isTerm(true);
    } else {
      props.isTerm && props.isTerm(false);
    }
  };
  const selectCreditPull = (e: any) => {
    setSoftcreditcheck(!softcreditcheck);
    if (!softcreditcheck == true && termshceck == true) {
      props.isTerm && props.isTerm(true);
    } else {
      props.isTerm && props.isTerm(false);
    }
  };

  return (
    <>
      {AccountInfo &&
        AccountInfo.mca_account == false &&
        !showLoader && (
          <div>
            <TitleSection
              pageTitle={
                props.stage != 'onboarding' ? 'Sign Agreement' : ''
              }
              pageNumAndOutOf={
                props.stage != 'onboarding' ? '4/4' : ''
              }
              title={
                props.stage != 'onboarding'
                  ? 'Sign agreement to submit your application'
                  : 'Sign Agreement'
              }
              smallHeader={props.stage != 'onboarding' ? true : false}
            />
            {<div className="grey-divider-line"></div>}
            {
              <div id="contract-outer-container">
                <div id="contract-wrapper">
                  <div
                    id="contract-text"
                    onScroll={(e) => handleScroll(e)}
                  >
                    {theDocsPs}
                  </div>

                  <div id="force-load-of-font">- - </div>

                  {!gotToEndOfText && (
                    <div>
                      <p id="scroll-instructions">
                        {signedDocs
                          ? 'Scroll to read documents'
                          : 'Scroll and read the document to sign'}{' '}
                      </p>
                    </div>
                  )}

                  {gotToEndOfText && (
                    <div>
                      <div
                        id="signature-wrapper"
                        className="rounded-corner-section"
                      >
                        <p id="your-signature-label">
                          Your Generated Signature
                        </p>

                        <ActualSignature
                          fullName={userFullName as string}
                          getDataUrlCb={(theUrl: any) => {
                            setSignaturedataUrl(theUrl);
                          }}
                        />
                      </div>
                      <div id="force-load-of-font">- - </div>

                      <div className="checkboxdata-message-non-ny">
                        <input
                          type="checkbox"
                          onChange={() =>
                            setCheckedAgreeToSoftPull(
                              !checkedAgreeToSoftPull,
                            )
                          }
                          className="checkbox-design"
                        />
                        <p className="checkboxdata">
                          Soft credit pull authorization
                        </p>
                      </div>
                      <div className="agreementCreditNonNy">
                        <img src="info.svg" className="" alt="" />
                        <p id="scroll-credit-non-ny">
                          A soft credit pull will not impact your
                          credit, Payro will not do a hard credit
                          pull.
                        </p>
                      </div>
                      <p id="signature-disclaimer">
                        {getSignatureDisclaimer(
                          AccountInfo.legal_name as string,
                        )}{' '}
                      </p>

                      <PayroButton
                        variant={signedDocs ? 'green' : 'green-agree'}
                        startIcon={signedDocs ? checkIcon : false}
                        disabled={
                          (AccountInfo.mca_account == false &&
                            checkedAgreeToSoftPull == false) ||
                          signedDocs
                            ? true
                            : gotToEndOfText
                            ? false
                            : true
                        }
                        customHeight="50px"
                        customWidth="width-200"
                        centered={true}
                        onClick={() =>
                          signHandler().then((res) =>
                            console.log(res),
                          )
                        }
                      >
                        {signedDocs
                          ? 'Your Signature Has Been Recorded'
                          : 'I Agree & Sign'}
                      </PayroButton>
                    </div>
                  )}
                </div>
              </div>
            }

            <div id="signature-container">
              {}
              {}
            </div>

            {}
          </div>
        )}

      {AccountInfo && AccountInfo.mca_account && !showLoader && (
        <div>
          <TitleSection
            pageTitle={
              props.stage != 'onboarding' ? 'Accept Terms' : ''
            }
            pageNumAndOutOf={props.stage != 'onboarding' ? '4/4' : ''}
            title={'Terms & Conditions'}
            additionalClass={`term-title`}
          />
          {
            <div id="contract-outer-container">
              <div id="contract-wrapper">
                <div
                  id="contract-text"
                  className=" terms-sec"
                  onScroll={(e) => handleScroll(e)}
                >
                  {parse(termconditions)}
                </div>

                <div id="force-load-of-font">- - </div>

                <div className="checkboxdata-message">
                  <input
                    type="checkbox"
                    onChange={(e) => selectTerms(e)}
                    className="checkbox-design"
                  />
                  <p className="checkboxdata">
                    I agree to Payro's Terms & Conditions
                  </p>
                </div>
                <div className="checkboxdata-message">
                  <input
                    type="checkbox"
                    onChange={(e) => selectCreditPull(e)}
                    className="checkbox-design"
                  />
                  <p className="checkboxdata">
                    Soft credit pull authorization
                  </p>
                </div>
                <div className="agreementCredit">
                  <img src="info.svg" className="" alt="" />
                  <p id="scroll-credit">
                    A soft credit pull will not impact your credit,
                    Payro will not do a hard credit pull.
                  </p>
                </div>
                {/* <p className="checkboxdata">Authorization for soft pulls: Each Merchant and each Owner understands that by signing this Agreement, they are providing ‘written instructions’ to PAYRO under the Fair Credit Reporting Act, authorizing PAYRO to obtain information from their personal credit profile
                   or other information from Experian, TransUnion, and Equifax. Each Merchant and each Guarantor authorizes PAYRO to obtain such information solely to conduct a pre-qualification for credit</p> */}

                {/* {gotToEndOfText && (
                  <div>
                    <div
                      id="signature-wrapper"
                      className="rounded-corner-section"
                    >
                      <p id="your-signature-label">
                        Your Generated Signature
                      </p>
                      <ActualSignature
                        fullName={userFullName as string}
                        getDataUrlCb={(theUrl: any) => {
                          setSignaturedataUrl(theUrl);
                        }}
                      />
                    </div>
                    <p id="signature-disclaimer">
                      {getSignatureDisclaimer(
                        AccountInfo.legal_name as string,
                      )}{' '}
                    </p>

                    <PayroButton
                      variant={signedDocs ? 'green' : 'green-agree'}
                      startIcon={signedDocs ? checkIcon : false}
                      disabled={
                        signedDocs
                          ? true
                          : gotToEndOfText
                          ? false
                          : true
                      }
                      customHeight="50px"
                      customWidth="width-200"
                      centered={true}
                      onClick={() => signHandler().then((res) => console.log(res))}
                    >
                      {signedDocs ? 'Your Signature Has Been Recorded' : 'I Agree & Sign'}
                    </PayroButton>
                  </div>
                )} */}
              </div>
            </div>
          }

          <div id="signature-container">
            {}
            {}
          </div>

          {}
        </div>
      )}
    </>
  );
}

export default SignAgreements;
