import { formatDate, formatNumberAsDollars } from '../../../../utils';
import PlaidConnector from '../../../../widgets/PlaidConnector';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isPlaidConnectedState,
  pendingPaymentState,
  pendingTransactionsState,
} from '../../../../recoil-state/general-states';
import schedulePaymenticon from '../../../../common-icons/schedule-payment.png';
import schedulePaymentNone from '../../../../common-icons/schedule-payment-none.png';
import './index.scss';
import {
  GetAccountDtoPayroFinanceStatusEnum,
  LoanPayment,
} from '../../../../api-utils/generated-client';
import ProgressBar from '../../../../widgets/ProgressBar';
import NextScheduledPayment from '../../../Dashboard/DashboardComponents/NextScheduledPayment';
import PendingBalanceMessage from '../../../Dashboard/DashboardComponents/PendingBalanceMessage';

interface DashboardHighlightProps {
  outstandingBalance: number;
  remainingLineOfCredit: number;
  originalCreditLimit: number;
  totalOutstandingPrincipal?: number;
  status?: GetAccountDtoPayroFinanceStatusEnum;
  hasLoanNotInNewStatus?: boolean;
  pendingBalance?: number;
}
const LedgerHighlight = (props: DashboardHighlightProps) => {
  const pendingTransactions = useRecoilValue(
    pendingTransactionsState,
  );
  const TotalPercent = Math.round(
    ((props.totalOutstandingPrincipal || 0) /
      props.originalCreditLimit) *
      100,
  );

  const RoundedTotalPercent = Math.round(TotalPercent / 5) * 5;
  const [pendingPayment, setPendingPayment] =
    useRecoilState<LoanPayment>(pendingPaymentState);
  return (
    <div id="dashboard-highlight-container">
      <div className="balance-progress-bar-container">
        <div className="dashboard-balance-available-credit">
          <div className="balance-container">
            {/* <p id="outstanding-balance-title">Current Outstanding Balance</p> */}
            <p id="outstanding-balance-title">Capital Utilized</p>
            <div className="pending-balance-main-wrapper-ledger">
              <p id="outstanding-balance-number">
                {formatNumberAsDollars(
                  props.totalOutstandingPrincipal,
                )}
              </p>
              {pendingTransactions && <PendingBalanceMessage />}
            </div>
            {pendingTransactions &&
            props.pendingBalance &&
            props.pendingBalance > 0 ? (
              <p id="remaining-balance-title">
                Pending Balance:{' '}
                {formatNumberAsDollars(props.pendingBalance)}{' '}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className="available-credit-wrapper">
            <p
              id={
                props.status ==
                GetAccountDtoPayroFinanceStatusEnum.Approved
                  ? 'remaining-credit-title'
                  : 'remaining-credit-title-not-active'
              }
            >
              Available Capital
              {/* Available Credit */}
            </p>
            <p
              id={
                props.status ==
                GetAccountDtoPayroFinanceStatusEnum.Approved
                  ? 'remaining-credit-number'
                  : 'remaining-credit-number-not-active'
              }
            >
              {formatNumberAsDollars(props.remainingLineOfCredit)}
              {pendingTransactions && (
                <PendingBalanceMessage section="available-credit" />
              )}
            </p>

            <p
              id={
                props.status ==
                GetAccountDtoPayroFinanceStatusEnum.Approved
                  ? 'original-limit'
                  : 'original-limit-not-active'
              }
            >
              Funding Limit:{' '}
              {formatNumberAsDollars(props.originalCreditLimit)}
            </p>
          </div>
        </div>
        <ProgressBar
          percentComplete={RoundedTotalPercent}
          // percentComplete={formatNumberAsDollars(props.totalOutstandingPrincipal)}
          // percentComplete={50}
          sectionName="LedgerHome"
        ></ProgressBar>
        <div />
      </div>
      {props.status == GetAccountDtoPayroFinanceStatusEnum.Approved &&
        props.hasLoanNotInNewStatus && (
          <>
            <div className="pending-payment-and-next-scheduled-wrapper">
              {pendingPayment.expected_settle_date != null ? (
                <div className="pending-payments-orange-wrapper-ledger-detail">
                  <p className="pending-payment-orange-text">
                    Your pending payment of{' '}
                    {formatNumberAsDollars(
                      pendingPayment.transaction_amount,
                    )}{' '}
                    will
                    <br /> clear on{' '}
                    {formatDate(pendingPayment.expected_settle_date)}.
                  </p>
                </div>
              ) : (
                <></>
              )}
              <div className="next-scheduled-payment-wrapper">
                <NextScheduledPayment />
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default LedgerHighlight;
