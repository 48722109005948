import PayroButton from '../widgets/PayroButton';
import NextIcon from '../common-icons/next-arrow.svg';
import BackIcon from '../common-icons/back-arrow.svg';
import CheckIcon from '../common-icons/checkIconWhite.png';
import ProgressCircleLock from '../pages/Application/SectionMarker/ProgressCircleLock.svg';
import './footer-buttons.css';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CreateBankAccountDto,
  UpdateBankAccountDto,
} from '../api-utils/generated-client';
import {
  isNextButtonDisabledState,
  currentScreenState,
  signedDocsState,
  signatureDataUrlState,
  documentSectionsState,
  showDocsState,
  docVersionState,
  furthestScreenState,
  clientState,
  contactsState,
  contactDisableState,
  submitApplicationState,
} from '../recoil-state/application-stage-states';
import {
  bankAccountToCreateState,
  chaseBankAccountToUpdateState,
} from '../recoil-state/request-funding-states';
import {
  accountRecordState,
  allAccountUsersContactInfoState,
  FundingStepStateType,
  mainSectionState,
  userInfoState,
} from '../recoil-state/general-states';
import { useEffect, useState } from 'react';
import { getClient } from '../api-utils/general-utils';
import {
  isThereFutureFinchPayroll,
  numOfEmployeesState,
  opportunityRecordState,
  payrollAmountState,
  payrollAverageMonthlySalesState,
  payrollCompanyState,
  payrollFrequencyState,
} from '../recoil-state/finch-states';
import {
  GetAccountDto,
  UserObject,
} from '../api-utils/generated-client';

import { DocumentSection } from '../pages/Application/SignAgreements/document-utils';
import {
  fundingStepState,
  dealDraftState,
  uploadedFilesState,
  isAllBankInfoValidState,
  bankAccountsState,
  needsManualFormState,
  bankAccountToUpdateState,
} from '../recoil-state/request-funding-states';
import { persistAllBankInfo } from '../pages/RequestFunding/ConnectBankAccounts/utils';
import { applicationStageSectionNames } from '../constants';
export type SectionMarkerSection =
  | 'CompanyInfo'
  | 'PayrollInfo'
  | 'BankStatements'
  | 'SignAgreements';
interface FooterButtonProps {
  nextType?: 'button' | 'reset' | 'submit' | 'getFunded' | 'cancel';
  formToSubmit?: string;
  SignAgreement?: boolean;
  signatureDataUrl?: any;
  documentVersion?: any;
  customNextText?: string;
  hideBackButton?: boolean;
  hideNextButton?: boolean;
  nextDisabled?: boolean;
  updateFooter?: any;
  pageNumAndOutOf?: any;
  IsTerms?: any;
  setLoader?: any;
  //userAddressState?: any;
  mca_account?: boolean;
  selectedCahAdvancePlan?: any;
  currentSection?: SectionMarkerSection;
  furthestSection?: SectionMarkerSection;
  screenNamesOnAppPage?: (
    newScreenName: string,
    furthestScreen: string,
  ) => void;
  isManualBank?: boolean | false;
}
const FooterButtons = (props: FooterButtonProps) => {
  let history = useHistory();
  const mainSection = useRecoilValue(mainSectionState);
  const [currentScreen, setCurrentScreen] = useRecoilState<any>(
    currentScreenState,
  );

  const [bankAccountToUpdate, setBankAccountToUpdate] =
    useRecoilState(bankAccountToUpdateState);
  const [fundingStep, setFundingStep] =
    useRecoilState(fundingStepState);
  const [isNextDisabled, setIsNextDisabled] = useRecoilState(
    isNextButtonDisabledState,
  );
  const [backScreenName, setBackScreenName] = useState<any>();
  const [nextScreenName, setNextScreenName] = useState<any>();
  const [hideBackButton, setHideBackButton] = useState(false);
  const [hideNextButton, setHideNextButton] = useState(false);
  const [customNextText, setCustomNextText] = useState<any>();
  const [pageNumAndOutOf, setPageNumAndOutOf] = useState<any>();
  const [client, setClient] = useRecoilState<any>(clientState);
  const [contactDisable, setContactDisable] = useRecoilState(
    contactDisableState,
  );
  const [submitApplication, setSubmitApplication] = useRecoilState(
    submitApplicationState,
  );
  const [chaseBankAccountToUpdate, setChaseBankAccountToUpdate] =
    useRecoilState<UpdateBankAccountDto>(
      chaseBankAccountToUpdateState,
    );
  submitApplicationState;
  const [payrollCompany, setPayrollCompany] = useRecoilState<any>(
    payrollCompanyState,
  );
  const [payrollAmount, setPayrollAmount] = useRecoilState<any>(
    payrollAmountState,
  );
  const [averageMonthlySales, setAverageMonthlySales] =
    useRecoilState<any>(payrollAverageMonthlySalesState);
  const [numOfEmployees, setNumOfEmployees] = useRecoilState<any>(
    numOfEmployeesState,
  );
  const [payrollFrequency, setPayrollFrequency] = useRecoilState<any>(
    payrollFrequencyState,
  );
  const [opportunityRecord, setOpportunityRecord] =
    useRecoilState<any>(opportunityRecordState);

  const [needsManualForm, setNeedsManualForm] = useRecoilState(
    needsManualFormState,
  );
  const [AccountDetail, setAccountDetail] =
    useRecoilState<GetAccountDto>(accountRecordState);
  const contacts = useRecoilValue(contactsState);
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const isAllBankInfoValid = useRecoilValue(isAllBankInfoValidState);

  const [furthestScreen, setFurthestScreen] = useRecoilState<any>(
    furthestScreenState,
  );

  const [signedDocs, setSignedDocs] =
    useRecoilState<boolean>(signedDocsState);
  const [signatureDataUrl, setSignaturedataUrl] = useRecoilState<any>(
    signatureDataUrlState,
  );
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  const [isManualBankAdd, setIsManualBankAdd] =
    useState<boolean>(false);
  const [documentSections, setDocumentSections] = useRecoilState<
    DocumentSection[]
  >(documentSectionsState);
  const [showDocs, setShowDocs] = useRecoilState(showDocsState);
  const [docVersion, setDocVersion] =
    useRecoilState<string>(docVersionState);
  const isThereFuturePayroll = useRecoilValue(
    isThereFutureFinchPayroll,
  );
  // const [bankAccount, setBankAccount] = useRecoilState<CreateBankAccountDto>(bankAccountToCreateState);
  const [bankAccountToCreate, setBankAccountToCreate] =
    useRecoilState<CreateBankAccountDto>(bankAccountToCreateState);

  const uploadedPayrollStatements = useRecoilValue(
    uploadedFilesState,
  );
  const userInfo = useRecoilValue(userInfoState);
  const getClientFunc = async () => {
    if (client) {
      return client;
    } else {
      const apiClient = await getClient();
      setClient(apiClient);
    }
  };
  const updateCurrentScreen = async (newScreenName: string) => {
    if (mainSection === 'Application') {
      setCurrentScreen(newScreenName);
      checkIfCurrentScreenIsNewFurthestScreen(newScreenName);
      await client.userInfoControllerUpdateUserInfo({
        current_screen: newScreenName,
      });

      props.screenNamesOnAppPage
        ? props.screenNamesOnAppPage(newScreenName, furthestScreen!)
        : console.log('else');
    } else if (mainSection === 'Onboarding') {
      setFundingStep(newScreenName as FundingStepStateType);
    }
  };
  // console.log('props.userAddressState',props.userAddressState)
  useEffect(() => {
    if (props.isManualBank) {
      setIsManualBankAdd(props.isManualBank);
    }
  }, [props.isManualBank]);

  const setFooterFunction = () => {
    if (mainSection === 'Application') {
      switch (currentScreen) {
        //--------APPLICTION SECTION SCREENS--------
        case 'CompanyInfo':
          setBackScreenName('Home');
          setNextScreenName('PayrollInfo');
          setHideBackButton(true);
          setIsNextDisabled(false);
          setCustomNextText('');
          break;
        case 'PayrollInfo':
          setBackScreenName('CompanyInfo');
          setNextScreenName('BankInfo');
          setHideBackButton(false);
          setIsNextDisabled(false);
          setCustomNextText('');
          setHideNextButton(false);
          break;
        case 'BankInfo':
          setBackScreenName('PayrollInfo');
          setNextScreenName('SignAgreements');
          setHideBackButton(false);
          setIsNextDisabled(false);
          setCustomNextText('');
          setHideNextButton(false);
          break;
        case 'SignAgreements':
          setBackScreenName('BankInfo');
          setIsNextDisabled(AccountDetail.mca_account ? true : false);
          setHideNextButton(AccountDetail.mca_account ? false : true);
          setCustomNextText(
            AccountDetail.mca_account ? 'Submit Application' : '',
          );

          //setNextScreenName('Important-mca-updates');
          break;
        // case 'Important-mca-updates':
        //   // setBackScreenName('BankInfo');
        //   setIsNextDisabled(true);
        //   setHideBackButton(true);
        //   setHideNextButton(true);
        //   break;
      }
    } else if (mainSection === 'Onboarding') {
      switch (fundingStep) {
        case 'funding-amount':
          setBackScreenName('funding-amount');
          setNextScreenName(
            props.mca_account ? 'cash-advance' : 'repayment-date',
          );
          setIsNextDisabled(
            props.mca_account
              ? !dealRecord.funding_amount ||
                  dealRecord.funding_amount < 5000 ||
                  !AccountDetail ||
                  dealRecord.funding_amount >
                    (AccountDetail!.credit_amount_available ?? 0) ||
                  uploadedPayrollStatements?.length < 1
              : !dealRecord.funding_amount ||
                  dealRecord.funding_amount < 5000 ||
                  !dealRecord?.actual_payroll_amount ||
                  dealRecord?.actual_payroll_amount < 5000 ||
                  !dealRecord?.payroll_due_date ||
                  !AccountDetail ||
                  dealRecord.funding_amount >
                    (AccountDetail!.credit_amount_available ?? 0),
          );
          setCustomNextText('');
          setHideBackButton(true);
          setPageNumAndOutOf('1/4');
          break;
        case 'repayment-date':
          setBackScreenName('funding-amount');
          setNextScreenName('bank-info');
          setIsNextDisabled(false);
          setCustomNextText('');
          setPageNumAndOutOf('2/4');
          break;
        case 'cash-advance':
          setBackScreenName('funding-amount');
          setNextScreenName('bank-info');
          setIsNextDisabled(!dealRecord.PlanType);
          setHideNextButton(false);
          setCustomNextText('');
          setPageNumAndOutOf('2/4');
          break;
        case 'bank-info':
          setBackScreenName(
            props.mca_account ? 'cash-advance' : 'repayment-date',
          );
          // setBackScreenName('repayment-date');
          setNextScreenName(
            needsManualForm
              ? 'manual-bank-form'
              : props.mca_account
              ? 'cash-advance-agreement'
              : 'verify-payroll',
          );
          // console.log('isAllBankInfoValid',isAllBankInfoValid)
          // console.log('isNextDisabled',isNextDisabled)
          setIsNextDisabled(isNextDisabled);
          setCustomNextText('');
          setPageNumAndOutOf('3/4');
          break;
        case 'manual-bank-form':
          setBackScreenName('bank-info');
          setNextScreenName(
            props.mca_account
              ? 'cash-advance-agreement'
              : 'verify-payroll',
          );
          setIsNextDisabled(isNextDisabled);
          setCustomNextText('Save');

          break;
        case 'verify-payroll':
          setBackScreenName(
            needsManualForm ? 'manual-bank-form' : 'bank-info',
          );
          setNextScreenName('confirm');
          setIsNextDisabled(uploadedPayrollStatements?.length < 1);

          setCustomNextText('Next');
          setPageNumAndOutOf('4/4');
          setHideNextButton(false);
          break;
        case 'cash-advance-agreement':
          // setBackScreenName(needsManualForm ? 'manual-bank-form' : 'bank-info');
          setNextScreenName('confirm');
          setIsNextDisabled(false);
          setCustomNextText('Continue');
          setPageNumAndOutOf('4/4');
          setHideNextButton(false);
          break;
        case 'cash-advance-agreement':
          setBackScreenName(
            needsManualForm ? 'manual-bank-form' : 'bank-info',
          );
          setNextScreenName('confirm');
          setIsNextDisabled(!props.SignAgreement);
          setCustomNextText('I Agree & Sign Document');
          setPageNumAndOutOf('4/4');
          setHideNextButton(false);
          break;
        case 'confirm':
          setBackScreenName(
            props.mca_account
              ? 'cash-advance-agreement'
              : 'verify-payroll',
          );
          // setNextScreenName(props.nextType == 'getFunded' ? 'funded' : 'Onboarding');
          setNextScreenName('funded');
          // setHideNextButton(false);
          // setIsNextDisabled(false);
          {
            props.mca_account
              ? setHideNextButton(false)
              : setHideNextButton(true);
          }
          setCustomNextText('Cancel Request');
          setPageNumAndOutOf('');
          break;
      }
    }
  };
  // console.log('currentScreen',currentScreen)
  const nextOnClick = async () => {
    const apiClient = await getClient();
    if (!apiClient) {
      return;
    }
    if (mainSection === 'Application') {
      switch (currentScreen) {
        case 'CompanyInfo':
          if (apiClient && AccountDetail) {
            apiClient.accountsControllerUpdate(AccountDetail);
            if (!contactDisable) {
              apiClient.contactsControllerUpsertAll({ contacts });
            }
            // if (apiClient) {
            //   const accountInfo =
            //     await apiClient.accountsControllerGetMyInfo();
            //   setAccountDetail(accountInfo.data);
            // }
          }
          break;
        case 'PayrollInfo':
          if (apiClient) {
            let accdetails = AccountDetail;
            if (payrollCompany) {
              accdetails = {
                ...AccountDetail,
                payroll_company: payrollCompany,
              };
            }
            // console.log('accdetails',accdetails)
            apiClient.accountsControllerUpdate(accdetails);
            // apiClient.accountsControllerUpdate(AccountDetail);
            apiClient.opportunitiesControllerUpdate({
              uuid: opportunityRecord.uuid,
              number_of_employees_range: numOfEmployees,
              how_often_do_you_run_payroll: payrollFrequency,
              average_payroll_amount: payrollAmount,
              average_monthly_sales_range: averageMonthlySales,
            });
          }
          break;
        case 'BankInfo':
          break;
        case 'SignAgreements':
          if (AccountDetail && AccountDetail.mca_account) {
            props.setLoader && props.setLoader(true);
            setSignedDocs(true);
            setShowDocs(false);
            await apiClient.documentsControllerSignDocuments({
              signature: signatureDataUrl,
              documentVersion: docVersion as string,
              isCashAdvance: true,
            });
            window.location.reload();
            props.setLoader && props.setLoader(false);
          } else {
            setSignedDocs(true);
            setShowDocs(false);
            await apiClient.documentsControllerSignDocuments({
              signature: signatureDataUrl,
              documentVersion: docVersion as string,
            });

            window.location.reload();
          }

          break;
      }
    } else if (mainSection === 'Onboarding') {
      switch (fundingStep) {
        case 'repayment-date':
          apiClient.dealsControllerUpdate({
            selected_num_of_weeks_in_portal:
              dealRecord.selected_num_of_weeks_in_portal ?? 2,
          });
          break;
        case 'cash-advance':
          let draftdealplan: any =
            props.selectedCahAdvancePlan &&
            props.selectedCahAdvancePlan;
          if (draftdealplan) {
            apiClient.dealsControllerUpdate({
              FinalPaymentDate: draftdealplan.FinalPaymentDate,
              PlanType: draftdealplan.PlanType,
              NumberofWeeks: draftdealplan.NumberofWeeks,
              FactorRate: draftdealplan.FactorRate,
              TotalAmountPayback: draftdealplan.TotalAmountPayback,
              TotalAdvanceCost: draftdealplan.TotalAdvanceCost,
              WeeklyPaymentAmount: draftdealplan.WeeklyPaymentAmount,
              EstFinalPaymentDate: draftdealplan.EstFinalPaymentDate,
              isCashAdvance: true,
            });
          }
          break;
        case 'cash-advance-agreement':
          const signHandler = async () => {
            const client = await getClient();
            if (!client) {
              return;
            }
            // setShowLoader(true);
            // await client.documentsControllerCashAdvanceSignDocuments({
            //   signature: props && props.signatureDataUrl,
            //   documentVersion: props.documentVersion
            // });
            // if (props.stage == 'onboarding') {
            //   setFundingStep('funding-amount');
            // } else {
            //   window.location.reload();
            // }
          };
          signHandler().then((res) => console.log(res));
          break;
        case 'bank-info':
          // if(isManualBankAdd){
          //   if(bankAccountToCreate){
          //     const t = await apiClient.bankAccountsControllermanualCreate(
          //       bankAccountToCreate,
          //     );
          //     console.log('t accounts========>',t)

          //     if (t) {
          //       const b = await client.bankAccountsControllerFindAll();
          //       console.log('b accounts========>',b)
          //       await setBankAccounts(b.data);
          //       await persistAllBankInfo(dealRecord, setDealRecord, b.data,true);
          //     }
          //   }
          // }else{
          persistAllBankInfo(dealRecord, setDealRecord, bankAccounts);
          // }
          break;
        case 'bank-info':
          if (isManualBankAdd) {
            // console.log('FOOTER isManualBankAdd', isManualBankAdd);
            if (bankAccountToCreate) {
              const t = await apiClient.bankAccountsControllerCreate(
                bankAccountToCreate,
              );
              console.log('t accounts========>', t);

              if (t) {
                const b =
                  await client.bankAccountsControllerFindAll();
                console.log('b accounts========>', b);
                await setBankAccounts(b.data);
                await persistAllBankInfo(
                  dealRecord,
                  setDealRecord,
                  b.data,
                );
              }
            }
          } else {
            persistAllBankInfo(
              dealRecord,
              setDealRecord,
              bankAccounts,
            );
          }
          break;
        case 'manual-bank-form':
          {
            setHideBackButton(true);
            // console.log('entered save bank function');
            const apiClient = await getClient();
            if (!apiClient) {
              return;
            }
            await setChaseBankAccountToUpdate({
              ...chaseBankAccountToUpdate,
              is_real_bank_numbers: true,
            });
            const res = await apiClient.bankAccountsControllerUpdate(
              chaseBankAccountToUpdate.uuid
                ? chaseBankAccountToUpdate.uuid
                : '',
              chaseBankAccountToUpdate,
            );
            // console.log('res', res);
            const b = await apiClient.bankAccountsControllerFindAll();
            await setBankAccounts(b.data);
            await setNeedsManualForm(false);
          }
          break;
        case 'confirm':
          // console.log('cancel request pressed');
          // const client = await getClient();
          try {
            // cancel deal here;
            setDealRecord({
              ...dealRecord,
              FinalPaymentDate: new Date().toDateString(),
              PlanType: '',
              NumberofWeeks: 0,
              FactorRate: 0,
              TotalAmountPayback: 0,
              TotalAdvanceCost: 0,
              WeeklyPaymentAmount: 0,
              EstFinalPaymentDate: new Date().toDateString(),
            });
            const dealStatus =
              await client?.dealsCancelControllerUpdate({
                FinalPaymentDate: new Date(),
                PlanType: '',
                NumberofWeeks: 0,
                FactorRate: 0,
                TotalAmountPayback: 0,
                TotalAdvanceCost: 0,
                WeeklyPaymentAmount: 0,
                EstFinalPaymentDate: new Date(),
                isCashAdvance: true,
              });

            if (dealStatus?.status == 200) {
              history.push('/cancelled');
              setFundingStep('cancelled');
            } else {
              history.push('/cancelled');
              setFundingStep('cancelled');
            }
          } catch {
            // setFundingStep('failed');
          }
          break;
        default:
          return;
      }
    }
    window.scrollTo(0, 0);
  };
  const backOnClick = async () => {
    if (currentScreen !== 'PayrollInfo') return;
    const apiClint = await getClient();
    if (!apiClint) {
      return;
    }
    if (mainSection === 'Onboarding') {
      switch (fundingStep) {
        case 'repayment-date':
          apiClint.dealsControllerUpdate({
            selected_num_of_weeks_in_portal:
              dealRecord.selected_num_of_weeks_in_portal ?? 2,
          });
          break;
        case 'cash-advance':
          // apiClint.dealsControllerUpdate({

          // });
          break;
        case 'bank-info':
          persistAllBankInfo(dealRecord, setDealRecord, bankAccounts);
          break;
          // case 'manual-bank-form':
          //   {
          //     if (bankAccountToUpdate.uuid) {
          //       const t = await apiClint.bankAccountsControllerUpdate(
          //         bankAccountToUpdate.uuid,
          //         bankAccountToUpdate,
          //       );
          //       if (t) {
          //         const b =
          //           await client.bankAccountsControllerFindAll();
          //         await setBankAccounts(b.data);
          //       }
          //     }
          //   }

          break;
        default:
          return;
      }
    }
    const apiClient = await getClient();
    if (apiClient) {
      apiClient.accountsControllerUpdate({
        payroll_company: payrollCompany,
      });
      apiClient.opportunitiesControllerUpdate({
        uuid: opportunityRecord.uuid,
        number_of_employees_range: numOfEmployees,
        how_often_do_you_run_payroll: payrollFrequency,
        average_payroll_amount: payrollAmount,
      });
    }
  };
  const checkIfCurrentScreenIsNewFurthestScreen = (
    newScreenName: string | undefined,
  ) => {
    if (applicationStageSectionNames) {
      const possibleNewScreenNameIndex =
        applicationStageSectionNames.findIndex(
          (x) => x.apiRef == newScreenName,
        );

      const oldScreenNameIndex =
        applicationStageSectionNames.findIndex(
          (x) => x.apiRef == props.furthestSection,
        );
      if (possibleNewScreenNameIndex > oldScreenNameIndex) {
        setFurthestScreen(newScreenName);
      }
    }
  };
  useEffect(() => {
    setFooterFunction();
    getClientFunc();
  }, [
    currentScreen,
    mainSection,
    fundingStep,
    dealRecord,
    isAllBankInfoValid,
    isThereFuturePayroll,
    uploadedPayrollStatements,
    needsManualForm,
  ]);
  useEffect(() => {
    props.SignAgreement && props.SignAgreement == true
      ? setIsNextDisabled(false)
      : props.SignAgreement == false
      ? setIsNextDisabled(!isNextDisabled)
      : '';
  }, [props.SignAgreement]);

  useEffect(() => {
    // console.log('props.IsTerms',props.IsTerms)

    props.IsTerms && props.IsTerms == true
      ? setIsNextDisabled(false)
      : setIsNextDisabled(true);
  }, [props.IsTerms]);

  // console.log('IsNextDisabled',isNextDisabled)

  return (
    <>
      <div
        id="footer-button-wrapper"
        className={hideBackButton ? 'center-next' : ''}
      >
        <PayroButton
          onClick={() => {
            if (mainSection === 'RepeatFunding') {
              setFundingStep('funding-amount');
            } else {
              backOnClick();
              updateCurrentScreen(backScreenName);
            }
          }}
          hidden={hideBackButton}
          startIcon={BackIcon}
          variant="back-button"
          className={'accent-background-color'}
        >
          {props.nextType === 'cancel' || nextScreenName === 'funded'
            ? 'Edit Details'
            : 'Back'}
        </PayroButton>
        <p
          className={
            !hideBackButton
              ? 'page-number'
              : 'page-number-hide-back-button'
          }
        >
          {pageNumAndOutOf}
        </p>

        <PayroButton
          onClick={() => {
            nextOnClick();
            updateCurrentScreen(nextScreenName);
          }}
          endIcon={
            customNextText == 'Submit Application'
              ? CheckIcon
              : NextIcon
          }
          hidden={hideNextButton || props.nextType == 'cancel'}
          disabled={isNextDisabled}
          variant={
            (customNextText == 'Submit' && !isNextDisabled) ||
            props.nextType == 'submit'
              ? 'green'
              : props.nextType == 'cancel'
              ? 'red-white'
              : 'purple'
          }
          customWidth={
            props.nextType == 'submit' || customNextText == 'Submit'
              ? 'width-210'
              : props.nextType == 'cancel'
              ? 'width-200'
              : 'width-130'
          }
          // className={`accent-background-color ${props.nextType === 'cancel' || nextScreenName === 'funded' ? 'cancelreruest' : ''} || ${props.nextType === 'getFunded' || nextScreenName === 'confirm' ? 'agree' : ''}`}

          className={`${
            AccountDetail && AccountDetail.mca_account
              ? `accent-background-color ${
                  props.nextType === 'cancel' ||
                  nextScreenName === 'funded'
                    ? 'cancelreruest'
                    : ''
                } ${
                  props.nextType === 'getFunded' ||
                  nextScreenName === 'confirm'
                    ? 'agree'
                    : ''
                }
                ${
                  customNextText &&
                  customNextText == 'Submit Application'
                    ? 'submit-success'
                    : ''
                }
                `
              : `accent-background-color ${
                  props.nextType === 'cancel' ||
                  nextScreenName === 'funded'
                    ? 'cancelreruest'
                    : ''
                } ${
                  props.nextType === 'getFunded' ||
                  nextScreenName === 'confirm'
                    ? ''
                    : ''
                }`
          }`}

          // className={props.nextType === 'cancel' || nextScreenName === 'funded' ? 'cancelreruest' :'accent-background-color'}
        >
          {props.nextType == 'cancel'
            ? 'Cancel Request'
            : props.nextType == 'submit'
            ? 'Submit Application'
            : props.nextType == 'getFunded'
            ? 'Get funded!'
            : customNextText
            ? customNextText
            : 'Next'}
        </PayroButton>
      </div>
      {nextScreenName == 'verify-payroll' && (
        <div className="data-container">
          <img
            className="lock-icon-bank"
            src={ProgressCircleLock}
            alt="image"
          />
          <p className="data-encrypted-text">
            {' '}
            End-to-end encryption.
          </p>
        </div>
      )}
      {nextScreenName == 'cash-advance-agreement' && (
        <div className="data-container">
          <img
            className="lock-icon-bank"
            src={ProgressCircleLock}
            alt="image"
          />
          <p className="data-encrypted-text">
            {' '}
            All data is protected with bank
            <br /> security end-to-end encryption.
          </p>
        </div>
      )}
    </>
  );
};

export default FooterButtons;
