import { useRef, useState } from 'react';
import PayroInput from '../../../widgets/PayroInput';
import './index.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PayroSelect from '../../../widgets/PayroSelect';
import { useRecoilState } from 'recoil';
import { dealDraftState } from '../../../recoil-state/request-funding-states';
import { getClient } from '../../../api-utils/general-utils';
import calendarIcon from './calendericon.svg';

import { formatDate } from '../../../utils';
import { format } from 'date-fns';

export default () => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const [showDropdown, setShowdropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);

  const openDatePicker = () => {
    setIsOpen(true);
  };

  const handleDateChange = async (date: Date) => {
    await setSelectedDate(date);
    setIsOpen(false);
    // console.log(format(date, 'yyyy-MM-dd'), 'k Date');
    await savePayrollDueDate(format(date, 'yyyy-MM-dd'));
  };

  const savePayrollDueDate = async (payrollDueDate: string) => {
    // console.log(payrollDueDate, 'the one passed in');
    await setDealRecord({
      ...dealRecord,
      funding_amount: dealRecord.funding_amount,
      payroll_due_date: payrollDueDate,
    });
    const client = await getClient();
    if (!client) {
      return;
    }
    await client
      .dealsControllerUpdate({
        funding_amount: dealRecord.funding_amount,
        payroll_due_date: payrollDueDate,
      })
      .then(() => {
        // console.log('deal', dealRecord);
      });
    // console.log('due date', dealRecord.payroll_due_date);
  };
  return (
    <>
      <div>
        <p className="payroll-check-date-header">
          Select Payroll Check Date:
        </p>
        <div
          className="repeat-funding-date-picker-wrapper"
          onClick={() => {
            openDatePicker();
          }}
        >
          <div className="date-picker-container">
            <div className="date-picker-input">
              {
                <DatePicker
                  id="date-picker-input"
                  ref={datePickerRef}
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  dateFormat="MM/dd/yyyy" // Format to display month/date/year
                  placeholderText={
                    dealRecord.payroll_due_date
                      ? formatDate(dealRecord.payroll_due_date)
                      : 'Select a Date'
                  }
                  onClickOutside={() => setIsOpen(false)}
                  open={isOpen}
                  onInputClick={() => setIsOpen(true)}
                />
              }
            </div>
          </div>
          <img
            onClick={() => {
              // console.log('hiii');
              openDatePicker();
            }}
            className="repeat-funding-calendar-icon"
            src={calendarIcon}
          />
        </div>
      </div>
    </>
  );
};
