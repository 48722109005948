import { useContext, useEffect, useState } from 'react';
import { getClient } from '../../../api-utils/general-utils';
import { MessageContext } from '../../../context';
import TitleSection from '../../../Header/title-section';
import { isFeatureOn } from '../../../utils';
import Uploader from '../../../widgets/Uploader';
import infoIcon from '../../../common-icons/info-icon.svg';
import './index.css';
// import { useRecoilState } from 'recoil';
import { isNextButtonDisabledState } from '../../../recoil-state/application-stage-states';
import PlaidConnector from '../../../widgets/PlaidConnector';
import {
  isPlaidConnectedState,
  mainSectionState,
} from '../../../recoil-state/general-states';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  accountRecordState,
  opportunityState,
} from '../../../recoil-state/general-states';

export interface PayroFile {
  filename: string;
}

const BankInfo = (props: any) => {
  const [statements, setStatements] = useState<any>();
  const AccountInfo = useRecoilValue(accountRecordState);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useRecoilState(
    isPlaidConnectedState,
  );

  const [
    checkedNotUploadingBankStatement,
    setCheckedNotUploadingBankStatement,
  ] = useState<boolean>(false);
  const [gotAllFinicity, setGotAllFinicity] = useState(false);
  const [manualBank, setManualBank] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useRecoilState(
    isNextButtonDisabledState,
  );
  const mainSection = useRecoilValue(mainSectionState);
  const [showManualUploader, setShowManualUploader] =
    useState<boolean>(false);
  const messageContext = useContext(MessageContext);

  const finicityFeatureOn: boolean = isFeatureOn('Finicity');

  const uploadFiles = async (filesToUpload: any[]) => {
    const client = await getClient();

    if (!client) {
      return;
    }

    for (let i = 0; i < filesToUpload.length; i++) {
      let singleFile = filesToUpload[i];
      if (singleFile.size > 10_000_000) {
        messageContext.addMessage({
          level: 'error',
          message: 'File Size is limited to 10MB',
        });
        return;
      }

      try {
        const urlRes =
          await client.documentsControllerUploadStatementsUrl({
            fileName: singleFile.name,
            documentType: 'bank-statement',
          });

        const options = {
          method: 'PUT',
          body: singleFile,
          mode: 'cors',
        };
        await fetch(urlRes.data, options as RequestInit);
      } catch {
        messageContext.addMessage({
          level: 'error',
          message: 'PDF uploads only',
        });
        return 'fail';
      }
    }

    getFiles();
  };

  const deleteFile = (fileName: string) => {
    getClient().then((client) => {
      if (!client) {
        return;
      }
      client
        .documentsControllerDeleteStatement({
          fileName,
          documentType: 'bank-statement',
        })
        .then(() => {
          getFiles();
        });
    });
  };

  const getFiles = () => {
    getClient().then((client) => {
      if (!client) {
        return;
      }
      client
        .documentsControllerGetBankStatements()
        .then((res: any) => {
          setUploadedFiles(res.data as string[]);
          if (res.data.length >= 1) {
            //console.log('res', res.data.length);
            setShowManualUploader(true);
          }
        });
    });
  };

  useEffect(() => {
    getFiles();
  }, []);
  useEffect(() => {
    setIsNextDisabled(
      isConnected !== 'yes' &&
        uploadedFiles.length < 1 &&
        checkedNotUploadingBankStatement != true,
    );
  });
  const showuploder = () => {
    const pdfshow = document.querySelector('statementlink');
    console.log('rtrtrtrtr', pdfshow);
  };

  //  console.log('AccountInfo.legal_address_state',AccountInfo.legal_address_state)

  return (
    <>
      {AccountInfo.legal_address_state && AccountInfo.mca_account ? (
        <div>
          <TitleSection
            pageTitle="Bank Statements"
            pageNumAndOutOf="3/4"
            centered={true}
            title="You are almost there!"
            additionalClass={`banksec`}
            subtitle="Connect your main bank account or upload your bank statements to complete the financial analysis."
          />
          {process.env.REACT_APP_SHOW_PLAID === 'yes' && (
            <PlaidConnector />
          )}
          {manualBank || uploadedFiles.length > 0 ? (
            <>
              {/* {isConnected !== 'yes' && process.env.REACT_APP_SHOW_PLAID === 'yes' && (
                <>
                  <TitleSection
                    pageTitle=""
                    pageNumAndOutOf=""
                    centered={true}
                    title=""
                    subtitle=""
                  />
                </>
              )} */}

              <div className="showuploader">
                <h4 className="manual-upload-title">
                  Upload Your Bank Statements Manually
                  {uploadedFiles && uploadedFiles.length <= 0 && (
                    <span
                      className="cancel-right"
                      onClick={() => setManualBank(!manualBank)}
                    >
                      Cancel
                    </span>
                  )}
                </h4>
                {isConnected !== 'yes' && (
                  <>
                    <Uploader
                      inputChangeHandler={async (files: any) => {
                        setStatements(files);
                        await uploadFiles(files);
                      }}
                      instructionsText="Upload 6 Statements Here"
                      uploadedFiles={uploadedFiles}
                      deleteFile={(fileName: any) =>
                        deleteFile(fileName)
                      }
                      getLatestUploadedFiles={getFiles}
                      isCAEnabled={true}
                      doctype={`bank-statement`}
                    />
                  </>
                )}
              </div>
            </>
          ) : isConnected == 'no' ? (
            <div className="bankstatement">
              <span>Don't want to connect your bank?</span>{' '}
              <a
                href="#"
                id="statementlink"
                onClick={() => setManualBank(!manualBank)}
              >
                Upload Bank Statements
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div>
          <TitleSection
            pageTitle="Connect Bank"
            pageNumAndOutOf="3/4"
            centered={true}
            title="Connect your bank"
            subtitle="Last 6 months of main operating account"
          />
          {process.env.REACT_APP_SHOW_PLAID === 'yes' && (
            <PlaidConnector justShowConnected={true} />
          )}

          {mainSection === 'Application'
            ? console.log(mainSection, 'true')
            : console.log(mainSection, 'false')}
          {isConnected !== 'yes' &&
            uploadedFiles &&
            uploadedFiles.length <= 0 && (
              <p className="manual-text-black">
                My bank does not support plaid:{' '}
                <span
                  className="manual-text-purple"
                  onClick={() => setShowManualUploader(true)}
                >
                  Upload Bank Statements
                </span>
              </p>
            )}
          {isConnected !== 'yes' && showManualUploader && (
            <>
              <div className="manual-upload-main-wrapper">
                <div className="header-and-cancel-wrapper">
                  <p className="manual-upload-title">
                    Upload Your Bank Statements Manually
                  </p>{' '}
                  {uploadedFiles && uploadedFiles.length <= 0 && (
                    <p
                      className="cancel-text-style"
                      onClick={() => setShowManualUploader(false)}
                    >
                      Cancel
                    </p>
                  )}
                </div>
                <p className="manual-upload-subtitle">
                  Statements of the last 6 months of your main
                  operating account
                </p>

                <Uploader
                  inputChangeHandler={async (files: any) => {
                    setStatements(files);
                    await uploadFiles(files);
                  }}
                  instructionsText="Upload 6 Statements Here"
                  uploadedFiles={uploadedFiles}
                  deleteFile={(fileName: any) => deleteFile(fileName)}
                  getLatestUploadedFiles={getFiles}
                />
              </div>
            </>
          )}
        </div>
      )}
      {isConnected !== 'yes' && uploadedFiles.length < 1 ? (
        <>
          <div className="checkboxdata-message-bank">
            <input
              type="checkbox"
              checked={checkedNotUploadingBankStatement}
              onChange={() =>
                setCheckedNotUploadingBankStatement(
                  !checkedNotUploadingBankStatement,
                )
              }
              className="checkbox-design"
            />
            <p className="checkboxdata">
              I want to skip this step for now
            </p>
          </div>
          {checkedNotUploadingBankStatement ? (
            <div className="message-to-user-and-icon-wrapper">
              <img src={infoIcon} />
              <p className="message-to-user">
                Skipping this step will delay the underwriting process
              </p>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default BankInfo;
