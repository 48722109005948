import { useEffect, useState, useContext } from 'react';
import FooterButtons from '../../../Footer/footer-buttons';
import arrowimg from '../../../common-icons/arrow.svg';
import arrowimg_dark from '../../../common-icons/arrowimg_dark.svg';
import { accountRecordState } from '../../../recoil-state/general-states';
import { DateTime } from 'luxon';
import './index.scss';
import { getClient } from '../../../api-utils/general-utils';
import { formatNumberAsDollars } from '../../../utils';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  dealDraftState,
  sectionState,
  transactionAvailabilityState,
} from '../../../recoil-state/request-funding-states';
// import {
//   CATransactionAvailability,
// } from '../../../api-utils/generated-client';
import { catransactionAvailabilityState } from '../../../recoil-state/request-funding-states';
import {
  TransactionAvailability,
  GetAccountDto,
} from '../../../api-utils/generated-client';
import Loader from '../../../widgets/Loader';
import { MessageContext } from '../../../context';
import CashAdvanceAgreement from '../CashAdvanceAgreement';

const CashAdvance = (props: any) => {
  const section = useRecoilValue(sectionState);
  const [fundingTime, setFundingTime] = useState<any>('');
  const [acceleratePaybackDate, setAcceleratePaybackDate] =
    useState<any>('');
  const [standardPaybackDate, setStandardPaybackDate] =
    useState<any>('');
  const [dealRecord, setDealRecord] = useRecoilState(dealDraftState);
  const [transactionAvailability, setTransactionAvailability] =
    useRecoilState<TransactionAvailability>(
      transactionAvailabilityState,
    );
  //const [userAddressState, setuserAddressState] = useState('');
  const [mca, setMca] = useState<boolean>(false);
  const [nextScreenName, setNextScreenName] = useState<any>();
  const [date, setdate] = useState('');

  const [accountDetails, setAccountDetails] =
    useRecoilState<GetAccountDto>(accountRecordState);
  const [StandardPlan, setStandardPlan] = useState<any>();
  const [AcceleratedPlan, setAcceleratedPlan] = useState<any>();
  const [monthlyRevenue, setMonthlyRevenue] = useState<number>(0);
  const [Userdata, setUserdata] = useState(true);
  const [addingPLan, setaddingPLan] = useState(false);
  const [curretPlan, setcurretPlan] = useState<any>('');
  const [catransactionAvailability, setCATransactionAvailability] =
    useRecoilState(catransactionAvailabilityState);

  const onWeekNumberSelect = async (weekNumber: number) => {
    setDealRecord({
      ...dealRecord,
      selected_num_of_weeks_in_portal: weekNumber,
    });
    const client = await getClient();
    if (!client) {
      return;
    }
    await client.dealsControllerUpdate({
      selected_num_of_weeks_in_portal: weekNumber,
    });
  };
  // console.log('transactionAvailability================>',transactionAvailability)
  // const loadedTransactionAvailability =
  //   transactionAvailability &&
  //   transactionAvailability.paybackDates &&
  //   transactionAvailability.paybackDates.length > 0;

  const messageContext = useContext(MessageContext);
  useEffect(() => {
    const getTransactionAvailability = async () => {
      // if (!loadedTransactionAvailability) {
      //   const client = await getClient();
      //   if (client) {
      //     const availability =
      //       await client.dealsControllerGetTransactionAvailability();
      //     setTransactionAvailability(availability.data);
      //   }
      // }
      const client = await getClient();
      if (client) {
        const availability =
          await client.dealsControllerGetCATransactionAvailability();
        // if(availability && availability?.data && availability?.data?.fundingDate){
        //   setEstdate(availability.data.fundingDate);
        // }

        if (
          availability &&
          availability?.data &&
          availability?.data?.StandardFunding
        ) {
          setStandardPaybackDate(availability.data.StandardFunding);
        }
        if (
          availability &&
          availability?.data &&
          availability?.data?.AcceleratedFunding
        ) {
          setAcceleratePaybackDate(
            availability.data.AcceleratedFunding,
          );
        }
        setCATransactionAvailability(availability.data);
        let t_today = DateTime.now().toISODate();
        let t_tomorrow = DateTime.now().plus({ days: 1 }).toISODate();
        if (
          availability?.data?.fundingDate &&
          t_today == availability.data.fundingDate
        ) {
          setFundingTime('today');
        } else if (
          availability?.data?.fundingDate &&
          t_tomorrow == availability.data.fundingDate
        ) {
          setFundingTime('tomorrow');
        } else {
          setFundingTime(availability.data.fundingDate);
        }
      }
    };
    getTransactionAvailability().then(() => {});
  }, []);

  useEffect(() => {
    if (
      dealRecord &&
      dealRecord.PlanType &&
      dealRecord.PlanType != ''
    ) {
      setcurretPlan(dealRecord.PlanType);
    }
  }, [dealRecord]);

  const standard = async () => {
    if (props.IsRepeatFunding && props.IsRepeatFunding == true) {
      setaddingPLan(true);
    }
    setDealRecord({
      ...dealRecord,
      FinalPaymentDate: StandardPlan.FinalPaymentDate,
      PlanType: StandardPlan.PlanType,
      NumberofWeeks: StandardPlan.NumberofWeeks,
      selected_num_of_weeks_in_portal: StandardPlan.NumberofWeeks,
      FactorRate: StandardPlan.FactorRate,
      TotalAmountPayback: StandardPlan.TotalAmountPayback,
      TotalAdvanceCost: StandardPlan.TotalAdvanceCost,
      WeeklyPaymentAmount: StandardPlan.WeeklyPaymentAmount,
      EstFinalPaymentDate: StandardPlan.EstFinalPaymentDate,
      // NumberofWeeksLeft: StandardPlan.NumberofWeeksLeft,
    });
    const client = await getClient();
    if (!client) {
      return;
    }
    await client
      .dealsControllerUpdate({
        FinalPaymentDate: StandardPlan.FinalPaymentDate,
        PlanType: StandardPlan.PlanType,
        NumberofWeeks: StandardPlan.NumberofWeeks,
        FactorRate: StandardPlan.FactorRate,
        TotalAmountPayback: StandardPlan.TotalAmountPayback,
        TotalAdvanceCost: StandardPlan.TotalAdvanceCost,
        WeeklyPaymentAmount: StandardPlan.WeeklyPaymentAmount,
        EstFinalPaymentDate: StandardPlan.EstFinalPaymentDate,
        // NumberofWeeksLeft: StandardPlan.NumberofWeeksLeft,
        selected_num_of_weeks_in_portal: StandardPlan.NumberofWeeks,
        isCashAdvance: true,
      })
      .then((res) => {
        setaddingPLan(false);
        if (res.status == 200) {
          if (
            props.IsRepeatFunding &&
            props.IsRepeatFunding == true
          ) {
            props.changeStep();
          }
        } else {
          messageContext.addMessage({
            message: 'Error Please try again later',
            level: 'error',
          });
        }
      })
      .catch((err) => {
        setaddingPLan(false);
        messageContext.addMessage({
          message: 'Error Please try again later',
          level: 'error',
        });
      });
  };
  const advance = async () => {
    if (props.IsRepeatFunding && props.IsRepeatFunding == true) {
      setaddingPLan(true);
    }
    setDealRecord({
      ...dealRecord,
      FinalPaymentDate: AcceleratedPlan.FinalPaymentDate,
      PlanType: AcceleratedPlan.PlanType,
      NumberofWeeks: AcceleratedPlan.NumberofWeeks,
      selected_num_of_weeks_in_portal: AcceleratedPlan.NumberofWeeks,
      FactorRate: AcceleratedPlan.FactorRate,
      TotalAmountPayback: AcceleratedPlan.TotalAmountPayback,
      TotalAdvanceCost: AcceleratedPlan.TotalAdvanceCost,
      WeeklyPaymentAmount: AcceleratedPlan.WeeklyPaymentAmount,
      EstFinalPaymentDate: AcceleratedPlan.EstFinalPaymentDate,
      // NumberofWeeksLeft: AcceleratedPlan.NumberofWeeksLeft,
    });
    const client = await getClient();
    if (!client) {
      return;
    }
    await client
      .dealsControllerUpdate({
        FinalPaymentDate: AcceleratedPlan.FinalPaymentDate,
        PlanType: AcceleratedPlan.PlanType,
        NumberofWeeks: AcceleratedPlan.NumberofWeeks,
        FactorRate: AcceleratedPlan.FactorRate,
        TotalAmountPayback: AcceleratedPlan.TotalAmountPayback,
        TotalAdvanceCost: AcceleratedPlan.TotalAdvanceCost,
        WeeklyPaymentAmount: AcceleratedPlan.WeeklyPaymentAmount,
        EstFinalPaymentDate: AcceleratedPlan.EstFinalPaymentDate,
        // NumberofWeeksLeft: AcceleratedPlan.NumberofWeeksLeft,
        selected_num_of_weeks_in_portal:
          AcceleratedPlan.NumberofWeeks,
        isCashAdvance: true,
      })
      .then((res) => {
        setaddingPLan(false);
        if (res.status == 200) {
          if (
            props.IsRepeatFunding &&
            props.IsRepeatFunding == true
          ) {
            props.changeStep();
          }
        } else {
          messageContext.addMessage({
            message: 'Error Please try again later',
            level: 'error',
          });
        }
      })
      .catch((err) => {
        setaddingPLan(false);
        messageContext.addMessage({
          message: 'Error Please try again later',
          level: 'error',
        });
      });
  };
  useEffect(() => {
    if (accountDetails && accountDetails?.monthlyrevenue) {
      setMonthlyRevenue(accountDetails.monthlyrevenue);
    }
  }, [accountDetails]);
  useEffect(() => {
    generatePlan();
    // console.log('hrererer')
  }, [
    catransactionAvailability,
    transactionAvailability,
    dealRecord,
    accountDetails,
  ]);

  const generatePlan = () => {
    // const stddate = new Date(Estdate);
    const stddate = standardPaybackDate;
    let standarddate = stddate.split('-');
    // stddate.setDate(stddate.getDate() + 84);
    // const days = stddate.getDate().toString().padStart(2, '0');
    // const months = (stddate.getMonth() + 1).toString().padStart(2, '0');
    // const years = stddate.getFullYear();
    // const standartnxtdate = `${months}/${days}/${years}`;
    const standartnxtdate = `${standarddate[1]}/${standarddate[2]}/${standarddate[0]}`;

    // const acclrtddate = new Date(Estdate);
    const acclrtddate = acceleratePaybackDate;
    let accelerateddate = acclrtddate.split('-');
    // acclrtddate.setDate(acclrtddate.getDate() + 28);
    // const day = acclrtddate.getDate().toString().padStart(2, '0');
    // const month = (acclrtddate.getMonth() + 1).toString().padStart(2, '0');
    // const year = acclrtddate.getFullYear();
    // const acceleratesnxtdate = `${month}/${day}/${year}`;
    const acceleratesnxtdate = `${accelerateddate[1]}/${accelerateddate[2]}/${accelerateddate[0]}`;
    // console.log('nxtdt',nxtdt)

    // let standardfigure:any = (monthlyRevenue/12);
    let standardfigure: any = monthlyRevenue / 4;
    let acceleratedfigure: any = monthlyRevenue / 4;
    let standardpaybackamount =
      ((dealRecord.funding_amount as number) * 1.13) / 12;
    let acceleratedbackamount =
      ((dealRecord.funding_amount as number) * 1.06) / 4;
    let standardpercentage: any =
      standardpaybackamount &&
      (standardpaybackamount * 100) / standardfigure;
    let acceleratedpercentage: any =
      acceleratedbackamount &&
      (acceleratedbackamount * 100) / acceleratedfigure;
    let standardTotalAmount =
      (dealRecord.funding_amount as number) * 1.13;
    let standardTotalAmountwithfee = standardTotalAmount + 150;
    let acceleratedTotalAmount =
      (dealRecord.funding_amount as number) * 1.06;
    let acceleratedTotalAmountwithfee = acceleratedTotalAmount + 150;
    if (dealRecord.funding_amount) {
      setStandardPlan({
        ...StandardPlan,
        FinalPaymentDate: standartnxtdate,
        PlanType: 'Standard',
        NumberofWeeks: 12,
        FactorRate: 1.13,
        TotalAmountPayback: standardTotalAmountwithfee,
        TotalAdvanceCost:
          standardTotalAmount - dealRecord.funding_amount,
        TotalAdvanceCostShow: standardTotalAmount,
        WeeklyPaymentAmount: standardTotalAmountwithfee / 12,
        EstFinalPaymentDate: standartnxtdate,
        standardfigure: standardfigure,
        standardpercentage: standardpercentage,
        // NumberofWeeksLeft: 4
      });
      setAcceleratedPlan({
        ...AcceleratedPlan,
        FinalPaymentDate: acceleratesnxtdate,
        PlanType: 'Accelerated',
        NumberofWeeks: 4,
        FactorRate: 1.06,
        TotalAmountPayback: acceleratedTotalAmountwithfee,
        TotalAdvanceCost:
          acceleratedTotalAmount - dealRecord.funding_amount,
        TotalAdvanceCostShow: acceleratedTotalAmount,
        WeeklyPaymentAmount: acceleratedTotalAmountwithfee / 4,
        EstFinalPaymentDate: acceleratesnxtdate,
        acceleratedfigure: acceleratedfigure,
        acceleratedpercentage: acceleratedpercentage,
        // NumberofWeeksLeft: 12
      });
    } else {
      setStandardPlan({
        ...StandardPlan,
        FinalPaymentDate: standartnxtdate,
        PlanType: 'Standard',
        NumberofWeeks: 12,
        FactorRate: 1.13,
        TotalAmountPayback: 0,
        TotalAdvanceCost: 0,
        TotalAdvanceCostShow: 0,
        WeeklyPaymentAmount: 0,
        EstFinalPaymentDate: standartnxtdate,
        // NumberofWeeksLeft: 4
        standardfigure: 0,
        standardpercentage: 0,
      });
      setAcceleratedPlan({
        ...AcceleratedPlan,
        FinalPaymentDate: acceleratesnxtdate,
        PlanType: 'Accelerated',
        NumberofWeeks: 4,
        FactorRate: 1.06,
        TotalAmountPayback: 0,
        TotalAdvanceCostShow: 0,
        WeeklyPaymentAmount: 0,
        EstFinalPaymentDate: acceleratesnxtdate,
        acceleratedfigure: 0,
        acceleratedpercentage: 0,
        // NumberofWeeksLeft: 12
      });
    }
  };
  useEffect(() => {
    const getUserInfo = async () => {
      const client = await getClient();
      if (!client) {
        return;
      }
      client
        .accountsControllerGetMyInfo()
        .then(async (accountInfo) => {
          setUserdata(false);
          if (accountInfo && accountInfo.data) {
            let mcaAccount = accountInfo.data.mca_account;
            setMca(mcaAccount ? mcaAccount: false);
          }
        });
    };
    getUserInfo().then(() => {});
  }, []);

  {
    // console.log('loadedTransactionAvailability',loadedTransactionAvailability)
    // console.log('Userdata',Userdata)
    // console.log('addingPLan',addingPLan)
  }
  if (fundingTime == '' || Userdata || addingPLan) {
    return <Loader message={addingPLan ? 'Adding plan' : ''} />;
  }
  return (
    <>
      {!props.IsRepeatFunding ? (
        <div
          id="request-funding-title-wrapper"
          className="onboarding"
        >
          <div
            id="request-funding-section-number"
            className="onboarding"
          >
            2<span id="title-period-after-number">.</span>
          </div>
          <div id="request-funding-section-title-text-wrapper">
            <h1
              id="request-funding-section-title"
              className="onboarding"
            >
              Select Advance Option
            </h1>
            <h1
              id="request-funding-section-subtitle"
              className="onboarding"
            >
              Select your desired payroll advance option
            </h1>
          </div>
          <div className="amounthead">
            <span className="fundamt">Funding Amount</span>
            <span className="cstamt">
              {formatNumberAsDollars(dealRecord.funding_amount)}
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={props.IsRepeatFunding ? 'noshowimg' : ''}>
        {props.IsRepeatFunding == true ? (
          <h1 className="Repet-funding">3. Select option</h1>
        ) : (
          ''
        )}
        {!props.IsRepeatFunding ? (
          <div className="head">
            <div className="topheading">
              <div className="Standardimg">
                <img src="money-banknote-bank.svg" alt="IMG" />
              </div>
              <div className="topheadingSection">
                <span className="heading">Accelerated</span>
                <span className="Contant">
                  Lower factor rate, with a higher weekly % of revenue
                  withheld.
                </span>
              </div>
            </div>
            <div className="topheading">
              <div className="Standardimg">
                <img src="standard.svg" alt="IMG" />
              </div>
              <div className="topheadingSection">
                <span className="heading">
                  {StandardPlan.PlanType}
                </span>
                <span className="Contant">
                  Greater factor rate, with a lower
                  <br />
                  weekly % of revenue withheld.
                </span>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="advance-option">
          <div
            className={`advance-option-section accelerated-plan ${
              curretPlan == 'Accelerated' ? 'current-selection' : ''
            }`}
          >
            {props.IsRepeatFunding &&
            props.IsRepeatFunding == true ? (
              <div className="topheading">
                <div className="Standardimg">
                  <img src="money-banknote-bank.svg" alt="IMG" />
                </div>
                <div className="topheadingSection">
                  <span className="heading">Accelerated</span>
                  <span className="Contant">
                    Lower factor rate, with a higher weekly % of
                    revenue withheld.
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="top-selection">
              <div className="total-c">
                <div className="cost-sec">
                  <span className="cost-txt">Total Advance Cost</span>
                  <span className="cost-amt">
                    {formatNumberAsDollars(
                      AcceleratedPlan.TotalAdvanceCost,
                    )}
                  </span>
                </div>
                <div className="rate-section">
                  <span className="rate">
                    {AcceleratedPlan.FactorRate}%
                  </span>
                  <span className="rate-factor">Factor Rate</span>
                </div>
              </div>
              <div className="total-weekly-c">
                <div className="cost-sec">
                  <span className="cost-txt">
                    Weekly Est. Payment:
                  </span>
                  <span className="cost-amt">
                    {formatNumberAsDollars(
                      AcceleratedPlan.WeeklyPaymentAmount,
                    )}
                  </span>
                </div>
                <div className="rate-section">
                  <span className="rate">
                    {AcceleratedPlan.NumberofWeeks}
                  </span>
                  <span className="rate-factor">Weeks</span>
                </div>
              </div>
              <p className="line-hint">
                Based on your weekly sales of{' '}
                {formatNumberAsDollars(
                  AcceleratedPlan.acceleratedfigure,
                )}{' '}
                - {AcceleratedPlan.acceleratedpercentage.toFixed()}%
                of the Revenue
              </p>
            </div>
            <div className="bottom-selection">
              <div className="bottom-total">
                <span className="cost-txt">Total Payback:</span>
                <div className="bottem-second">
                  <span className="rate-factorcost">
                    {formatNumberAsDollars(
                      AcceleratedPlan.TotalAmountPayback,
                    )}
                  </span>
                  <span className="incl">($150 fee incl.)</span>
                </div>
                <span className="rate-factor">
                  Based on average revenue
                </span>
              </div>
              <div className="bottom-estimated">
                <span className="Estimated2">
                  Estimated Final Payment Date:
                </span>
                <span className="date2">
                  {AcceleratedPlan?.EstFinalPaymentDate
                    ? AcceleratedPlan?.EstFinalPaymentDate
                    : ''}
                </span>
                <span className="month">1 month from now</span>
              </div>
            </div>
            <button
              id="Select"
              className="bottembutton"
              onClick={() => advance()}
            >
              Select Option
              {/* <img
                src={`${
                  curretPlan == 'Accelerated'
                    ? arrowimg_dark
                    : arrowimg
                }`}
                alt="Arrow"
              /> */}
            </button>
          </div>
          <div
            className={`advance-option-section standard-plan ${
              curretPlan == 'Standard' ? 'current-selection' : ''
            }`}
          >
            {props.IsRepeatFunding &&
            props.IsRepeatFunding == true ? (
              <div className="topheading">
                <div className="Standardimg">
                  <img src="standard.svg" alt="IMG" />
                </div>
                <div className="topheadingSection">
                  <span className="heading">
                    {StandardPlan.PlanType}
                  </span>
                  <span className="Contant">
                    Greater factor rate, with a lower
                    <br />
                    weekly % of revenue withheld.
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="top-selection">
              <div className="total-c">
                <div className="cost-sec">
                  <span className="cost-txt">Total Advance Cost</span>
                  <span className="cost-amt">
                    {formatNumberAsDollars(
                      StandardPlan.TotalAdvanceCost,
                    )}
                  </span>
                </div>
                <div className="rate-section">
                  <span className="rate">
                    {StandardPlan.FactorRate}%
                  </span>
                  <span className="rate-factor">Factor Rate</span>
                </div>
              </div>
              <div className="total-weekly-c">
                <div className="cost-sec">
                  <span className="cost-txt">
                    Weekly Est. Payment:
                  </span>
                  <span className="cost-amt">
                    {formatNumberAsDollars(
                      StandardPlan.WeeklyPaymentAmount,
                    )}
                  </span>
                </div>
                <div className="rate-section">
                  <span className="rate">
                    {StandardPlan.NumberofWeeks}
                  </span>
                  <span className="rate-factor">Weeks</span>
                </div>
              </div>
              <p className="line-hint">
                Based on your weekly sales of{' '}
                {formatNumberAsDollars(StandardPlan.standardfigure)} -{' '}
                {StandardPlan.standardpercentage.toFixed()}% of the
                Revenue
              </p>
            </div>
            <div className="bottom-selection">
              <div className="bottom-total">
                <span className="cost-txt">Total Payback:</span>
                <div className="bottem-second">
                  <span className="rate-factorcost">
                    {formatNumberAsDollars(
                      StandardPlan.TotalAmountPayback,
                    )}
                  </span>
                  <span className="incl">($150 fee incl.)</span>
                </div>
                <span className="rate-factor">
                  Based on average revenue
                </span>
              </div>
              <div className="bottom-estimated">
                <span className="Estimated2">
                  Estimated Final Payment Date:
                </span>
                <span className="date2">
                  {StandardPlan?.EstFinalPaymentDate
                    ? StandardPlan.EstFinalPaymentDate
                    : ''}
                </span>
                <span className="month">3 months from now</span>
              </div>
            </div>

            <button
              className="bottembutton"
              onClick={() => standard()}
            >
              Select Option
              {/* <img
                src={`${
                  curretPlan == 'Standard' ? arrowimg_dark : arrowimg
                }`}
                alt="Arrow"
              /> */}
            </button>
          </div>
        </div>
        {!props.IsRepeatFunding ? (
          <FooterButtons
            hideBackButton={false}
            nextDisabled={false}
            selectedCahAdvancePlan={
              dealRecord && dealRecord.PlanType == 'Standard'
                ? StandardPlan
                : AcceleratedPlan
            }
            mca_account={props.mca_account}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default CashAdvance;
