import { useEffect, useState } from 'react';
import PlaidConnector from '../../widgets/PlaidConnector';
import './index.scss';
import axios from 'axios';

const ConnectPlaid = () => {
  const [BankingInfo, setBankingInfo] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/accounts/banking-info`)
      .then((res) => {
        // console.log('loggging here');
        if (res.data && res.data.bankingInfo)
          setBankingInfo(res.data.bankingInfo);
      });
    localStorage.removeItem('redirectUrl');
  }, []);
  return (
    <div id="connect-plaid-page-wrapper">
      <h1 id="connect-your-bank-title">Connect your Bank</h1>
      <PlaidConnector justShowConnected={true} />
    </div>
  );
};

export default ConnectPlaid;
