import PayroButton from '../../widgets/PayroButton';
import Congrats from './congrats-icon.svg';
import congratsactive from './../../common-icons/congrats-active.svg';
import './congrats.scss';
import { useRecoilState } from 'recoil';
import checkIcon from './white-check.svg';
import NextIcon from '../../common-icons/next-arrow.svg';
import BackIcon from '../../common-icons/back-arrow.svg';
import { formatNumberAsDollars } from '../../utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fundingStepState } from '../../recoil-state/request-funding-states';
import { GetAccountDto } from '../../api-utils/generated-client';
import { accountRecordState } from '../../recoil-state/general-states';

export default () => {
  const accountRecord = useRecoilValue(accountRecordState);
  const setFundingStep = useSetRecoilState(fundingStepState);
  const [accountDetails, setAccountDetails] =
    useRecoilState<GetAccountDto>(accountRecordState);
  const showFunding = () =>
    setFundingStep(
      accountRecord.contract_signed
        ? 'funding-amount'
        : 'sign-contract',
    );
  return accountDetails && accountDetails.mca_account ? (
    <div id="congrats-wrapper">
      <img id="congrats-image-icon" src={congratsactive} />
      <h1 id="congrats-title">Application Approved</h1>
      <h1 id="ca-congrats-message">
        Your application was successfully approved!
      </h1>
      <h1 id="ca-amount-title">Your Payroll Advance Limit:</h1>
      <h1 id="congrats-approved-number">
        {formatNumberAsDollars(accountRecord.approved_credit_amount)}
      </h1>

      <div className="next-message-container">
        <p className="whats-next-text">What's Next?</p>

        <div className="next-message-container-text">
          <img id="circle-congrats" src={checkIcon} />
          <p className="explanation-text">
            {' '}
            Your Payroll funding application is approved. Now lets get
            your first payroll funded.
          </p>
        </div>

        <PayroButton
          className="Request-Funding"
          variant="purple"
          onClick={showFunding}
          // buttonSize="medium"
          customWidth="width-182"
          centered={true}
          endIcon={NextIcon}
          end-image={true}
        >
          Request Funding
        </PayroButton>
      </div>

      {/* <PayroButton
                variant="green-dashboard"
                onClick={showDashboard}
                centered={true}
                buttonSize="medium"


            >My Dashboard</PayroButton>
    */}
    </div>
  ) : (
    <div id="congrats-wrapper">
      <img id="congrats-image" src={Congrats} />
      <h1 id="congrats-title">Congrats!</h1>
      <h1 id="congrats-message">Approved Credit Amount:</h1>
      <h1 id="congrats-approved-number">
        {formatNumberAsDollars(accountRecord.approved_credit_amount)}
      </h1>

      <div className="next-message-container">
        <p className="whats-next-text">What's Next?</p>

        <div className="next-message-container-text">
          <img id="circle-congrats" src={checkIcon} />
          <p className="explanation-text">
            {' '}
            Your Payroll financing application is approved. Now let's
            get your first payroll funded.
          </p>
        </div>

        <PayroButton
          variant="purple"
          onClick={showFunding}
          // buttonSize="medium"
          customWidth="width-182"
          centered={true}
          endIcon={NextIcon}
          end-image={true}
        >
          My First Loan
        </PayroButton>
      </div>

      {/* <PayroButton
          variant="green-dashboard"
          onClick={showDashboard}
          centered={true}
          buttonSize="medium"


          >My Dashboard</PayroButton>
          */}
    </div>
  );
};
