import { formatDate, formatNumberAsDollars } from '../../../utils';
import './index.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
  GetRepaymentDto,
  LoanPayment,
  NewLoanDebitPayment,
} from '../../../api-utils/generated-client';
import PayroButton from '../../../widgets/PayroButton';
import { useEffect, useState, useContext } from 'react';
import { getClient } from '../../../api-utils/general-utils';
import PayroSelect, {
  SelectOption,
} from '../../../widgets/PayroSelectv2';
import PayroInput from '../../../widgets/PayroInput';
import Loader from '../../../widgets/Loader';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  payFullBalanceState,
  paymentFieldsState,
  scheduledPaymentsListRecoil,
  showHideClassNameState,
  showProcessingLoaderState,
  bankAccountsState,
} from '../../../recoil-state/request-funding-states';
import PayroRadioButtonGroup from '../../../widgets/PayroRadioButtonGroup';
import { activeRepaymentsState } from '../../../recoil-state/general-states';
import ScheduledIcon from '../../../common-icons/payment-scheduled-icon.svg';
import PaymentFailed from './PaymentFailed/PaymentFailed';
import DealFailed from '../../RequestFunding/DealFailed/DealFailed';
import { useHistory } from 'react-router-dom';
import InfoMessage from '../../../widgets/InfoMessage';
import { MessageContext } from '../../../context';

interface ModalProps {
  loanId: string;
  repaymentBalance: number | undefined;
  nextScheduledPaymentBankName: string | undefined;
  nextScheduledPaymentBankNumber: string | undefined;
  repaymentSfId?: string;
  repaymentUuid: string;
  repaymentPaybackDate: string;
  className: string;
}

export default (props: ModalProps) => {
  let history = useHistory();
  const [showHideClassName, setShowHideClassName] = useRecoilState(
    showHideClassNameState,
  );
  const [activeRepayments, setActiveRepaymentsList] = useRecoilState<
    GetRepaymentDto[]
  >(activeRepaymentsState);
  const [bankAccounts, setBankAccounts] =
    useRecoilState(bankAccountsState);
  const [paymentFields, setPaymentFields] =
    useRecoilState<NewLoanDebitPayment>(paymentFieldsState);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [date, setdate] = useState('');
  const [Holidaylist, setHolidaylist] = useState({});
  const [ableToSchedulePayment, setAbleToSchedulePayment] =
    useState<boolean>(true);
  const [payFullBalance, setPayFullBalance] = useRecoilState(
    payFullBalanceState,
  );
  const messageContext = useContext(MessageContext);
  const scheduledPaymentsList = useRecoilValue<LoanPayment[]>(
    scheduledPaymentsListRecoil,
  );

  const getAccountLastFour = (b: any) => {
    if (
      b.account_last_four != '' ||
      b.account_last_four != undefined ||
      b.account_last_four != null
    ) {
      return b.account_last_four;
    } else {
      getLastFourCharacters(b.bank_account_number);
    }
  };
  const getLastFourCharacters = (s: string | undefined) => {
    if (!s) {
      return '';
    }

    if (s.length <= 4) {
      return s;
    }

    return s.substr(s.length - 4, 4);
  };

  // ---------New HolidayApi-----

  useEffect(() => {
    const getTransactionAvailability = async () => {
      const client = await getClient();
      if (client) {
        const availability =
          await client.dealsControllerGetCATransactionAvailability();
        // console.log(availability.data.bankHolidays)
        setHolidaylist(availability.data.bankHolidays);
      }
    };
    getTransactionAvailability().then(() => {});
  }, []);

  // console.log(Holidaylist)
  const datesArray = Object.keys(Holidaylist);

  //   useEffect(() => {
  //     const getData = async () => {
  //       const client = await getClient();
  //       if (!client) {
  //         return;
  //       }
  //       const getDateApi =
  //         await client.paymentsControllerGetPaymentAvailability(
  //           props.repaymentUuid,
  //         );
  //       console.log(getDateApi, 'hindy');
  //       const effectiveDate = getDateApi.data.payment_date;
  //       if (getDateApi.data.payment_date == undefined) {
  //         setAbleToSchedulePayment(false);
  //       } else {
  //         setAbleToSchedulePayment(true);
  //       }
  //       if (props.repaymentSfId != undefined) {
  //         setPaymentFields({
  //           ...paymentFields,
  //           repaymentId: props.repaymentSfId,
  //           effectiveDate: getDateApi.data.payment_date,
  //         });
  //       }
  //       {
  //         effectiveDate != undefined &&
  //           activeRepayments.length > 0 &&
  //           setPaymentFields({
  //             ...paymentFields,
  //             effectiveDate: getDateApi.data.payment_date,
  //             repaymentId: props.repaymentSfId
  //               ? props.repaymentSfId
  //               : activeRepayments[0].salesforce_id,
  //             amount: 0,
  //           });
  //       }
  //     };

  //     getData();
  //   }, [paymentFields.effectiveDate]);

  useEffect(() => {}, [payFullBalance]);
  useEffect(() => {
    // setPaymentFields({
    //     ...paymentFields,
    //     repaymentId: props.repaymentSfId ? props.repaymentSfId : '',
    //     amount: props.repaymentBalance ? props.repaymentBalance : 0,
    // });
  }, [props]);
  useEffect(() => {
    setPaymentFields({
      ...paymentFields,
      repaymentId: props.repaymentSfId ? props.repaymentSfId : '',
      amount: props.repaymentBalance ? props.repaymentBalance : 0,
    });
  }, []);

  const onSubmit = async () => {
    const selectdate = new Date(paymentFields.effectiveDate);

    if (
      selectdate < new Date() ||
      [0, 6].includes(selectdate.getDay())
    ) {
      setdate(
        'Weekend or past dates not allowed to schedule payment.',
      );
    } else {
      setShowLoader(true);
      const client = await getClient();
      if (!client) {
        return;
      }

      await localStorage.setItem('paymentId', props.loanId);
      await localStorage.setItem(
        'repaymentUuid',
        props.repaymentUuid,
      );
      await localStorage.setItem(
        'repaymentPaybackDate',
        props.repaymentPaybackDate,
      );
      await localStorage.setItem(
        'paymentAmount',
        paymentFields.amount.toString(),
      );
      try {
        const schedulePayment: any =
          await client.paymentsControllerCreateLoanDebitPayment(
            paymentFields,
          );

        if (
          schedulePayment.status == 201 &&
          schedulePayment?.data &&
          schedulePayment?.data?.success !== false
        ) {
          setShowLoader(false);
          history.push('/payment-advance-scheduled');
        } else {
          setShowLoader(false);
          setdate(schedulePayment?.data?.message);
        }
      } catch (error) {
        setShowLoader(false);
        // history.push('/payment-failed');
      }
    }
  };
  const saveFundingAmount = async (paymentAmount: number) => {
    await setPaymentFields({
      ...paymentFields,
      amount: paymentAmount,
    });
  };
  const saveEffectiveDate = async (date: any) => {
    await setPaymentFields({
      ...paymentFields,
      effectiveDate: date,
    });
  };
  if (showLoader) {
    setPaymentFields({
      ...paymentFields,
      amount: 0,
    });
    if (paymentFields.effectiveDate == '') {
      console.log('empty');
    }
    return (
      <Loader
        fullPageLoader={true}
        message="Your payment is being processed"
      />
    );
  }

  // const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  function dateChange(event: any) {
    const newValue = event.target.value;
    console.log('Value changed to:', newValue);
  }

  const isWeekend = (date: Date): boolean => {
    const day = date.getDay();
    return (
      day === 1 || day === 2 || day === 3 || day === 4 || day === 5
    );
  };

  const isPastDate = (date: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const istodayDate = (date: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date.toDateString() <= today.toDateString();
  };

  const isDisabled = (date: Date): boolean => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    // console.log('isWeekend(date)',isWeekend(date))
    return (
      date.getTime() <= today.getTime() ||
      isWeekend(date) ||
      isPastDate(date) ||
      istodayDate(date)
    );
  };

  return (
    <>
      <div id="myModal" className={showHideClassName}>
        <div className="modal-content">
          <span
            onClick={() => {
              setShowHideClassName('modal display-none');
            }}
            className="close"
          >
            &times;
          </span>
          {/* {ableToSchedulePayment ? (
            <div className="payment-wrapper-unable-to-schedule">
              <img src={ScheduledIcon}></img>
              <p className="loan-id-title-style">
                Loan #{props.loanId}
              </p>
              <p className="payment-explanation-style">
                Your final payment for is already scheduled for {''}
                {formatDate(props.repaymentPaybackDate)}.
              </p>

              <InfoMessage
                theBackgroundColor="yellow"
                messageText="For further assistance, or to make any changes to your current payments, please call us at
                1-833-271-4499."
              ></InfoMessage>
            </div>
          ) : ( */}
          <>
            <p className="modal-header ">Schedule a payment</p>
            {scheduledPaymentsList &&
            scheduledPaymentsList.length > 0 ? (
              <InfoMessage
                theBackgroundColor="yellow"
                messageText="You have scheduled payments for this advance. Proceeding will schedule an additional payment."
              ></InfoMessage>
            ) : (
              //console.log(scheduledPaymentsList)
              ''
            )}
            <div>
              <p className={'ca modal-outstanding-balance-title'}>
                Current Outstanding Balance for advance #
                {props.loanId}
              </p>

              <p className="ca" id="outstanding-balance-number-modal">
                {formatNumberAsDollars(
                  props.repaymentBalance ? props.repaymentBalance : 0,
                )}
              </p>
            </div>

            {/* <>
                <PayroRadioButtonGroup
                  groupLabel="Payment Amount:"
                  options={[
                    {
                      label: 'Total Balance',
                      subLabel: ` ${formatNumberAsDollars(
                        props.repaymentBalance
                          ? props.repaymentBalance
                          : 0,
                      )}`,
                      value: 'yes',
                    },
                    { label: 'Other Amount', value: 'no' },
                  ]}
                  checkedValue={payFullBalance}
                  onValueChange={async (e) => {
                    setPayFullBalance(e.target.value as 'yes' | 'no');
                    if (e.target.value == 'yes') {
                      const number = props.repaymentBalance;
                      //   ? props.repaymentBalance.replace('$', '')
                      //   : '';
                      // const formattedNumber = number.replace(',', '');
                      setPaymentFields({
                        ...paymentFields,
                        amount: parseFloat(
                          number ? number.toString() : '',
                        ),
                      });
                    } else if (e.target.value == 'no') {
                      setPaymentFields({
                        ...paymentFields,
                        amount: 0,
                      });
                    }
                  }}
                  groupName="pay-full-balance"
                />
              </> */}

            <div>
              <p id="remitcheck">{date}</p>
              <div className="funding-amount-item actual-amount">
                <span id="Payment-amount">Payment Amount:</span>
                <PayroInput
                  onBlurFunction={(e: any) => {
                    saveFundingAmount(
                      parseFloat(e.target.value),
                    ).then(() => {});
                  }}
                  required
                  placeholder="0.00"
                  isOnlyNumbers
                  value={paymentFields.amount ?? 0}
                  onChange={(eventValue: any) => {
                    if (eventValue) {
                      setPaymentFields({
                        ...paymentFields,
                        amount: parseFloat(eventValue),
                      });
                    }
                  }}
                  label=""
                  variant="green"
                  type="currency"
                  error={
                    props.repaymentBalance
                      ? paymentFields.amount > props.repaymentBalance
                      : false
                  }
                  helperText={
                    props.repaymentBalance &&
                    paymentFields.amount > props.repaymentBalance
                      ? 'Payment amount cannot exceed the total balance'
                      : ''
                  }
                />
              </div>
              {/* <div className="funding-amount-item actual-amount">
                    <span id='Payment-amount'>Repayment Bank</span>
                    <PayroSelect
                        wrapperAdditionalClasses="select-content-bank"
                        options={bankAccounts.map((b) => {
                            return {
                            label: `${
                                b.bank_name ? b.bank_name : ''
                            } ${getAccountLastFour(b)}`,
                            value: b.uuid,
                            };
                        })}
                        onSelect={async (uuid: string) => {
                            const bankAccountWithThisUuid = bankAccounts.find(
                            (b) => b.uuid === uuid,
                            );
                        }}
                        selectName="deposit-bank"
                        placeholderText="deposit bank number"
                        defaultSelectedValue={``}
                        />
                </div> */}
              <div className="funding-amount-item actual-amount">
                <span id="Payment-amount">Payment Date:</span>
                <div className="new-date-picker">
                  {/* <DatePicker
                        value={paymentFields.effectiveDate}
                        onChange={(eventValue: any) => {
                          console.log('sdf',eventValue)
                          if (eventValue) {
                              setPaymentFields({
                                  ...paymentFields,
                                  effectiveDate: eventValue,
                              });
                              saveEffectiveDate(eventValue).then(() => {});
                          }
                      }}
                        filterDate={isDisabled}
                        placeholderText="MM/dd/yyyy"
                        minDate={new Date()} // Prevent selecting past dates
                        showYearDropdown
                        dateFormat="MM/dd/yyyy"
                        
                      /> */}
                  <DatePicker
                    value={paymentFields.effectiveDate}
                    onChange={(eventValue) => {
                      if (eventValue) {
                        const year = eventValue.getFullYear();
                        const month = String(
                          eventValue.getMonth() + 1,
                        ).padStart(2, '0');
                        const day = String(
                          eventValue.getDate(),
                        ).padStart(2, '0');
                        const formattedDate = `${year}-${month}-${day}`;
                        setPaymentFields({
                          ...paymentFields,
                          effectiveDate: formattedDate,
                        });
                        saveEffectiveDate(formattedDate).then(
                          () => {},
                        );
                      }
                    }}
                    filterDate={(date) => {
                      // Disable past dates
                      const currentDate = new Date();
                      // if (date < currentDate) {
                      //   return true;
                      // }
                      // Disable today
                      if (
                        date.getDate() === currentDate.getDate() &&
                        date.getMonth() === currentDate.getMonth() &&
                        date.getFullYear() ===
                          currentDate.getFullYear()
                      ) {
                        return false;
                      }
                      // Disable weekends (Saturday and Sunday)
                      const dayOfWeek = date.getDay();
                      // console.log('date',date)
                      // console.log('dayOfWeek',dayOfWeek)
                      if (dayOfWeek === 6 || dayOfWeek === 0) {
                        return false;
                      }

                      // Disable dates in the datesArray
                      const formattedDate = `${date.getFullYear()}-${String(
                        date.getMonth() + 1,
                      ).padStart(2, '0')}-${String(
                        date.getDate(),
                      ).padStart(2, '0')}`;
                      // console.log('datesArray',datesArray)
                      if (datesArray.includes(formattedDate)) {
                        return false;
                      }

                      return true;
                    }}
                    placeholderText="mm/dd/yyyy"
                    minDate={new Date()} // Prevent selecting past dates
                    showYearDropdown
                    dateFormat="mm/dd/yyyy"
                  />
                  {/* <DatePicker
                          // value={paymentFields.effectiveDate}
                          value={paymentFields.effectiveDate}
                          onChange={(eventValue) => {
                            if (eventValue) {
                              const year = eventValue.getFullYear();
                              const month = String(eventValue.getMonth() + 1).padStart(2, '0');
                              const day = String(eventValue.getDate() ).padStart(2, '0');
                              const formattedDate = `${year}-${month}-${day}`;
                              // console.log('Formatted Date:', formattedDate);
                              setPaymentFields({
                                ...paymentFields,
                                effectiveDate: formattedDate,
                              });
                              saveEffectiveDate(formattedDate).then(() => {});
                            }
                          }}
                          filterDate={isDisabled}
                          placeholderText="mm/dd/yyyy"
                          minDate={new Date()} // Prevent selecting past dates
                          showYearDropdown
                          dateFormat="mm/dd/yyyy"
                        /> */}
                </div>
                {/* <PayroInput
                        onBlurFunction={(e: any) => {
                            saveEffectiveDate(
                            e.target.value,
                            ).then(() => {});
                        }}
                        required
                        // placeholder="0.00"
                        value={paymentFields.effectiveDate}
                        onChange={(eventValue: any) => {
                            if (eventValue) {
                                setPaymentFields({
                                    ...paymentFields,
                                    effectiveDate: eventValue,
                                });
                            }
                        }}
                        label=""
                        variant="green"
                        type="date"
                        error={
                            !paymentFields.effectiveDate
                        }   
                    /> */}
              </div>

              {/* <div id="modal-wrapper">
                  <div id="modal-details-container">
                    <p className="modal-subtitles">
                      Debiting from Account:
                    </p>
                    <div className="outer-Paymentbox">
                        <span id='Payment-amount'>Repayment Bank</span>
                    <div className=''>
                        <div></div>
                    </div>
                    </div>
                    <span className="modal-details">
                      {props.nextScheduledPaymentBankName} ***{' '}
                      {props.nextScheduledPaymentBankNumber
                        ? props.nextScheduledPaymentBankNumber.slice(
                            props.nextScheduledPaymentBankNumber
                              .length - 4,
                          )
                        : ''}
                    </span>
                  </div>{' '}
                  <div id="modal-details-container">
                    <p className="modal-subtitles">Debiting Date:</p>
                    <span className="modal-details-payment-date">
                      {formatDate(paymentFields.effectiveDate)}
                    </span>
                  </div>
                </div> */}
              {/* <p className="notification-text">
                  The deadline to schedule next-day payment is 4:30 PM
                  EST
                </p> */}
              <div
                id="myBtn"
                className="button-wrapper-remit-payment-modal-cancel"
              >
                <PayroButton
                  buttonSize="medium"
                  variant="red-white"
                  onClick={() => {
                    setShowHideClassName('modal display-none');
                  }}
                >
                  Cancel Payment
                </PayroButton>
              </div>
              <div
                id="myBtn"
                className="button-wrapper-remit-payment-modal"
              >
                <PayroButton
                  buttonSize="medium"
                  disabled={
                    !paymentFields.amount ||
                    !paymentFields.effectiveDate
                  }
                  onClick={() => onSubmit()}
                >
                  Schedule Payment
                </PayroButton>
              </div>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </>
  );
};
